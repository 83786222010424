import { IconTrash, IconX } from "@tabler/icons"
import { Button } from "@mantine/core"
import { useState } from "react"
import { deleteDoc, doc } from "firebase/firestore"
import { showNotification } from "@mantine/notifications"
import { db } from "../../api/api-firebase"
import { useAppSelector } from "../../utils/hooks"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

interface Props {
  id: number;
  title: string;
  onRemoved?: any;
}

export const BtnRemoveMovie = ({ id, title, onRemoved }: Props) => {

  const rSwal = withReactContent(Swal)

  const [isLoading, setIsLoading] = useState(false);

  const userState = useAppSelector((state) => state.user)
  const docRef = doc(db, "users", userState.data.docId, 'movieList', id.toString());

  const removeMovie = async () => {

    setIsLoading(true);

    rSwal.fire({
      title: 'Sei sicuro?',
      text: "Il film verrà rimosso dalla tua watchlist e tutti i dati personali collegati andranno persi per sempre",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Elimina film',
      cancelButtonText: 'Annulla',
    }).then(async ({isConfirmed}) => {
      if (isConfirmed) {

        await deleteDoc(docRef);

        showNotification({
          title: "Film rimosso",
          message: `"${title}" è stato rimosso alla tua watchlist`,
          icon: <IconX />,
        })

        // Callback
        onRemoved && onRemoved()

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    })

  }

  return (
    <Button
      loading={isLoading}
      variant="default"
      fullWidth
      style={{paddingLeft: 10, paddingRight: 10}}
      onClick={() => removeMovie()}
    >
      <IconTrash size={14}/>
    </Button>
  )

}
