import {
  TextInput,
  Title,
  Container,
  useMantineTheme,
  ActionIcon,
  Loader,
  Table, FocusTrap,
} from '@mantine/core';

import { IconPlus, IconSearch, IconUser } from '@tabler/icons';
import { useEffect, useState } from "react";
import { searchFriends } from "../api/api-users";
import { Friend } from "../utils/types"
import { AvatarUser } from "../components/molecules/AvatarUser"
import { useAppSelector } from "../utils/hooks"
import { BtnHandleFriend } from "../components/molecules/BtnHandleFriend"

export function SearchFriend() {

  const theme = useMantineTheme();

  const [inputValue, setInputValue] = useState('')
  const [friendsList, setFriendsList] = useState<Friend[] | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const userState = useAppSelector((state) => state.user)

  useEffect(() => {
    if (inputValue && inputValue.length >= 3) {

      setIsLoading(true);

      const delayDebounceFn = setTimeout(() => {
        searchFriends(inputValue, userState.data.name.toLowerCase())
          .then((friends: Friend[]) => {
            setFriendsList(friends)
            setIsLoading(false);
          });
      }, 1000)

      return () => clearTimeout(delayDebounceFn)

    } else {
      setFriendsList(null);
      setIsLoading(false);
    }
  }, [inputValue])

  return (
    <>
      <Container size="sm">

        <Container size={420} my={40}>
          <FocusTrap active={true}>
            <TextInput
              icon={<IconUser size={18} stroke={1.5}/>}
              radius="xl"
              size="md"
              rightSection={
                isLoading && (
                  <Loader color="white" size={18}/>
                )
              }
              placeholder="Cerca un amico da aggiungere"
              rightSectionWidth={42}
              onChange={(e) => setInputValue(e.currentTarget.value)}
            />
          </FocusTrap>
        </Container>

        {
          friendsList &&
            <Table verticalSpacing="xl">
              <tbody>
                {
                  friendsList.map((friend, i) => (
                    <tr key={i}>
                      <td>
                        <AvatarUser
                          src={friend.avatar || ''}
                          name={friend.name || ''}
                          secondField={friend.email}
                          size={40}
                        />
                      </td>
                      <td align="right">
                        <BtnHandleFriend
                          size="xs"
                          userFrom={userState.data.docId}
                          userTo={friend.id}
                        />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
        }

      </Container>

    </>
  );
}
