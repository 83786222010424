import { Divider, Group, Title } from "@mantine/core"
import React from "react"

interface Props {
  label: string;
  value: React.ReactNode;
}

export const ListSimpleItem = ({ value, label }: Props) => {

  return (
    <>
      <Group position="apart" align="flex-start" noWrap>
        <Title order={6}>{label}</Title>
        {value}
      </Group>
      <Divider my="sm" />
    </>
  )
}
