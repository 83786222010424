import {
  IconCheck,
  IconEyeCheck, IconEyeOff,
} from "@tabler/icons"
import { Badge, Button, Modal, Overlay, Textarea, TextInput, Select } from "@mantine/core"
import { useEffect, useState } from "react"
import { useForm } from "@mantine/form"
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  serverTimestamp,
  query,
  updateDoc,
  where,
  addDoc
} from "firebase/firestore"
import { showNotification } from "@mantine/notifications"
import { db } from "../../api/api-firebase"
import { useAppSelector } from "../../utils/hooks"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
import { votesRange } from "../../utils/utils"
import { idPhantom } from "../../utils/constants"
import { sendNotification } from "../../api/api-notifications"

interface Props {
  id: number;
  title: string;
  action: 'asSeen' | 'asUnseen';
}

export const BtnMarkSeenState = ({ id, title, action }: Props) => {

  const rSwal = withReactContent(Swal)

  const [isLoading, setIsLoading] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [voteValues, setVoteValues] = useState<any[] | null>(null)

  const userState = useAppSelector((state) => state.user)
  const docRef = doc(db, "users", userState.data.docId, 'movieList', id.toString())

  const form = useForm({
    initialValues: {
      vote: 6,
    }
  });

  const markAsSeen = async (seen: boolean, values?: any) => {

    setIsLoading(true);

    await updateDoc(docRef, {
      watched: seen,
      vote: seen ? values : 0,
      watched_on: serverTimestamp()
    })

    if (seen) {

      // Film segnato come visto, mostro notifica
      showNotification({
        title: "Film segnato come visto",
        message: `"${title}" è stato segnato come visto, puoi trovarlo nella sezione "visti di recente."`,
        icon: <IconEyeCheck />,
        color: 'green'
      })

      // Invio notifica agli amici favourites / oppure a tutti
      const friends = await getDocs(collection(db, 'users', userState.data.docId, 'friendList'))

      // Valuta la possibilità di inviarlo solo a chi ti ha tra i preferiti

      await Promise.all(
        friends.docs.map(async user => {
          await sendNotification(
            user.id,
            "movieWatched",
            `${userState.data.name} ha visto il film "${title}" e l'ha votato ${values}`,
            userState.data.docId,
            `/movie/${id}`
          )
        })
      )


    } else {

      // Film segnato come non visto, mostro notifica
      showNotification({
        title: "Film segnato come non visto",
        message: `"${title}" è stato rimesso in watchlist`,
        icon: <IconEyeOff />,
      })
    }

    setIsLoading(false);
  }

  useEffect(() => {
    // Genero i possibili voti da 0 a 10 per la select
    setVoteValues(votesRange());
  }, [])

  return (
    <>
      <Button
        loading={isLoading}
        variant={action === 'asSeen' ? "filled" : "light"}
        fullWidth
        onClick={() => {
          if (action === 'asSeen') {
            setModalOpened(true)
          } else {
            setIsLoading(true);
            rSwal.fire({
              title: 'Sei sicuro?',
              text: 'Il film verrà segnato come "da vedere" e le informazioni personali saranno perse per sempre',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Segna come non visto',
              cancelButtonText: 'Annulla',
            }).then(({isConfirmed}) => {
              if (isConfirmed) {
                markAsSeen(false)
                setIsLoading(false);
              } else {
                setIsLoading(false);
              }
            })
          }
        }
      }
      >
        {/*Visto*/}
        {action === 'asSeen' ? 'Segna come visto' : 'Segna come da vedere'}
      </Button>

      <Modal
        opened={modalOpened}
        centered
        onClose={() => setModalOpened(false)}
        title={`Segna "${title}" come visto`}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            markAsSeen(action === 'asSeen', values.vote)
          })}>

          {isLoading && <Overlay opacity={0.3} color="#000"/>}

          <Select
            label="Assegna un voto da 1 a 10"
            placeholder="Assegna voto"
            data={voteValues}
            {...form.getInputProps('vote')}
          />
          <Button
            type="submit"
            fullWidth
            mt="xl"
            loading={isLoading && true}
          >
            Segna come visto
          </Button>

        </form>
      </Modal>
    </>
  )

}
