import {
  IconPlus,
  IconMinus, IconX,
  IconHourglassLow, IconCheck
} from "@tabler/icons"
import {
  Badge,
  Button,
  Modal,
  Overlay,
  Textarea,
  TextInput,
  Select,
  ButtonProps,
  Group,
  GroupProps
} from "@mantine/core"
import { useEffect, useState } from "react"
import { showNotification } from "@mantine/notifications"
import { friendshipConfirm, friendshipRemove, friendshipRequest } from "../../api/api-users"
import { useAppSelector } from "../../utils/hooks"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

interface Props {
  userFrom: string,
  userTo: string,
  position?: GroupProps['position'],
  size: ButtonProps['size'],
  onSubmit?: any // Callback per operazione conclusa
}

export const BtnHandleFriend = ({ userFrom, userTo, size, position, onSubmit }: Props) => {

  // UserFrom è sempre l'utente loggato all'app
  // UserTo è l'amico da confrontare per sapere se sono amici, se deve accettare la richiesta o è in pending

  const rSwal = withReactContent(Swal)

  const [isLoading, setIsLoading] = useState(false)
  const [friendshipState, setFriendshipState] = useState<'unknown' | 'waitingForResponse' | 'pending' | 'friends' | null>(null)

  const userState = useAppSelector(state => state.user)
  const friendListStore = useAppSelector(state => state.friendList.friendList)

  useEffect(() => {
    if (friendListStore) {

      setIsLoading(true);

      // Controllo se l'utente è nella lista
      const friend = friendListStore.find((user: any) => user.docId === userTo);

      if (friend) {
        if (!friend.isAccepted && friend.inPending) {
          // L'amico non ha ancora accettato, è in pending
          setFriendshipState('waitingForResponse')
        } else if (friend.isAccepted && friend.inPending) {
          // L'amico ti ha inviato una richiesta di amicizia
          setFriendshipState('pending')
        } else if (friend.isAccepted && !friend.inPending) {
          // L'amico ha già accettato e sono amici
          setFriendshipState('friends')
        }
        setIsLoading(false);
      } else {
        // Non sono ancora amici
        setFriendshipState('unknown');
        setIsLoading(false);
      }
    }
  }, [friendListStore])

  return (
    friendshipState === 'unknown' ?
      <Button
        size="xs"
        rightIcon={<IconPlus size={14} />}
        loading={isLoading}
        onClick={async () => {
          setIsLoading(true)
          await friendshipRequest(userFrom, userTo, userState.data.name)

          showNotification({
            title: "🙋 Richiesta amico inviata",
            message: `"Il tuo amico è stato avvisato della tua richiesta"`,
            icon: <IconPlus />,
          })

          setIsLoading(false)
          onSubmit && onSubmit()
        }}
      >
        Aggiungi
      </Button>
    : friendshipState === 'pending' ?
        <Button
          size="xs"
          rightIcon={<IconHourglassLow size={14} />}
          variant="light"
          disabled
        >
          Richiesta inviata
        </Button>
    : friendshipState === 'waitingForResponse' ?
        <Group position={position || 'left'}>
          <Button
            size="xs"
            rightIcon={<IconCheck size={14} />}
            variant="filled"
            onClick={async () => {
              await friendshipConfirm(userFrom, userTo, userState.data.name)

              showNotification({
                title: "🍾 Richiesta accettata",
                message: "La richiesta dell'amico è stata accettata, ora è nella tua lista amici",
                icon: <IconCheck />,
              })

              setIsLoading(false)
              onSubmit && onSubmit()
            }}
          >
            Accetta
          </Button>
          <Button
            size="xs"
            rightIcon={<IconX size={14} />}
            variant="outline"
            onClick={async () => {
              await friendshipRemove(userFrom, userTo)

              showNotification({
                title: "💁‍ Richiesta rifiutata",
                message: "La richiesta dell'amico è stata rifiutata",
                icon: <IconX />,
              })

              setIsLoading(false)
              onSubmit && onSubmit()
            }}
          >
            Rifiuta
          </Button>
        </Group>
    :
      <Button
        size="xs"
        rightIcon={<IconX size={14} />}
        loading={isLoading}
        variant="light"
        onClick={async () => {

          setIsLoading(true)

          rSwal.fire({
            title: 'Sei sicuro?',
            text: "L'amico sarà rimosso dalla tua lista amici",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Rimuovi amico',
            cancelButtonText: 'Annulla',
          }).then(async ({isConfirmed}) => {
            if (isConfirmed) {

              await friendshipRemove(userFrom, userTo)

              showNotification({
                title: "💁‍ Amico rimosso",
                message: "L'amico è stato rimosso dalla tua lista amici",
                icon: <IconX />,
              })

              setIsLoading(false)
              onSubmit && onSubmit()

            } else {
              setIsLoading(false)
            }
          })
        }}
      >
        Rimuovi amico
      </Button>
  )
}
