import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  appIsLoading: false,
  watchlistOrder: 'addedOn|desc',
  watchlistRecentlyViewedOrder: 'watchedOn|desc',
  themeSettings: {
    colorScheme: 'dark'
  }
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setAppIsLoading(state, action) {
      state.appIsLoading = action.payload
    },
    setWatchlistOrder(state, action) {
      state.watchlistOrder = action.payload
    },
    setWatchlistRecentlyViewedOrder(state, action) {
      state.watchlistRecentlyViewedOrder = action.payload
    },
    themeColorSchemeSwitch(state) {
      state.themeSettings.colorScheme = (state.themeSettings.colorScheme === 'light') ? 'dark' : 'light';
    },
  },
});

export const { themeColorSchemeSwitch, setWatchlistOrder, setWatchlistRecentlyViewedOrder, setAppIsLoading } = generalSlice.actions;

export default generalSlice.reducer
