import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Overlay,
} from '@mantine/core';
import {useState} from "react";
import { auth, db } from "../api/api-firebase";
import {signInWithEmailAndPassword} from "firebase/auth";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "@mantine/form";
import { collection, getDocs, query, where } from "firebase/firestore"
import { loginSuccess } from "../store/reducers/user"
import { updateMovieList } from "../store/reducers/movieList"
import { useAppDispatch } from "../utils/hooks"
import { showNotification } from "@mantine/notifications"
import { IconCheck, IconX } from "@tabler/icons"

export function UserSignin() {

  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  // const userCollectionRef = collection(db, "users");

  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email non valida'),
    },
  });

  const fetchUserData = async (userId: any) => {
    // Query per recuperare dettagli utente
    const qU = query(collection(db, "users"), where("uid", "==", userId));
    const doc = await getDocs(qU);

    // Query per recuperare la watchlist
    const qW = query(collection(db, "users", doc.docs[0].id, 'movieList'));
    const docWatchlist = await getDocs(qW);

    // Preparo l'array della watchlist
    let storeList: any[] = [];
    docWatchlist.docs.map((movie) => {
      let movieStored = movie.data();
      movieStored.id = movie.id;
      storeList = [...storeList, movieStored];
    })

    // Recupero docId dell'utente e i suoi dati del doc
    return {
      docId: doc.docs[0].id,
      userData: doc.docs[0].data(),
      watchList: storeList
    };
  }

  const signInUser = async (values: any) => {

    setIsLoading(true);

    try {
      const data = await signInWithEmailAndPassword(
        auth,
        values.email.trim(),
        values.password
      );

      if (data) {
        fetchUserData(data.user.uid).then(res => {

          res.userData.docId = res.docId

          dispatch(loginSuccess(res.userData));
          dispatch(updateMovieList(res.watchList));

          setIsLoading(false);

          showNotification({
            title: 'Hey! 👋🏻',
            message: "L'accesso è stato effettuato correttamente.",
            icon: <IconCheck />,
            color: 'green'
          })

          navigate("/");
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        err.message.includes('auth/user-not-found') ?
          showNotification({
            title: 'Utente inesistente',
            message: "L'utente che hai inserito non esiste",
            icon: <IconX />,
            color: 'red'
          })
          :
          showNotification({
            title: 'Errore generico',
            message: "Si è verificato un errore generico, riprova più tardi",
            icon: <IconCheck />,
            color: 'red'
          })

      }
      setIsLoading(false);
    }
  }

  return (
    <Container>

      <Title align="center" order={1}>Bentornato</Title>

      <Container size={420}>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Non sei ancora registrato?{' '}
          <Text component={Link} variant="link" to="/user/signup">Crea un account</Text>
        </Text>

        <form onSubmit={form.onSubmit((values) => signInUser(values))}>
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">

            {isLoading && <Overlay opacity={0.3} color="#000"/>}

            <TextInput label="Email" required {...form.getInputProps('email')} />
            <PasswordInput label="Password" required mt="md"  {...form.getInputProps('password')} />

            <Group position="apart" mt="md">
              {/*<Checkbox label="Remember me" />*/}
              <Text component={Link} variant="link" size="sm" to="/user/recover-password">Password dimenticata?</Text>
            </Group>

            <Button type="submit" fullWidth mt="xl" loading={isLoading && true}>Accedi</Button>

          </Paper>
        </form>

      </Container>
    </Container>
  );
}
