import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  isLoggingIn: false,
  isLogged: false,
  data: {
    docId: "",
    uid: "",
    name: "",
    email: "",
    avatar: "",
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginRequest(state) {
      state.isLoggingIn = true;
    },
    loginSuccess(state, action) {
      state.isLoggingIn = false;
      state.isLogged = true;
      state.data = action.payload;
    },
    loginFail(state) {
      state.isLoggingIn = false
    },
    logoutRequest(state) {
      state.isLoggingIn = false;
      state.isLogged = false;
      state.data = initialState.data
    }
  },
});

export const { loginRequest, loginSuccess, loginFail, logoutRequest } = userSlice.actions;

export default userSlice.reducer
