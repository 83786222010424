import { LoadingOverlay, MantineProvider } from '@mantine/core';
import {Navbar} from "./components/organisms/Navbar";
import {Dashboard} from "./pages/Dashboard";
import { Navigate, Route, Routes } from "react-router-dom";
import {UserSignin} from "./pages/UserSignin";
import {NotFound} from "./pages/NotFound";
import {FooterSimple} from "./components/organisms/Footer";
import {useAppDispatch, useAppSelector} from "./utils/hooks";
import {UserSignup} from "./pages/UserSignup";
import { useEffect, useState } from "react";
import {onAuthStateChanged} from "firebase/auth";
import { auth, db } from "./api/api-firebase";
import { logoutRequest } from "./store/reducers/user";
import {updateMovieList, resetMovieList} from "./store/reducers/movieList"
import {UserRecoverPassword} from "./pages/UserRecoverPassword";
import {SearchMovie} from "./pages/SearchMovie";
import { NotificationsProvider } from '@mantine/notifications';
import { Watchlist } from "./pages/Watchlist"
import {
	addDoc,
	collection,
	onSnapshot,
	setDoc,
	updateDoc,
	deleteField,
	orderBy,
	query,
	serverTimestamp,
	limit
} from "firebase/firestore"
import { MovieDetail } from "./pages/MovieDetail"
import { Friends } from "./pages/Friends"
import { SearchFriend } from "./pages/SearchFriend"
import { setAppIsLoading } from "./store/reducers/general"
import { resetFriendList, updateFriendList } from "./store/reducers/friendList"
import { FriendDetail } from "./pages/FriendDetail"
import { ScrollToTop } from "./utils/scrollToTop"
import { Helmet } from "react-helmet";
import { Notifications } from "./pages/Notifications"
import { updateNotifications, newNotifications, resetNotifications } from "./store/reducers/notifications"

const mainMenu = [
	{
		"link": "/watchlist",
		"label": "Watchlist"
	},
	{
		"link": "/recently-viewed",
		"label": "Visti di recente"
	},
	{
		"link": "/friends",
		"label": "Amici"
	},
];

const unsignedMenu = [
	{
		"link": "/user/signin",
		"label": "Accedi"
	},
	{
		"link": "/user/signup",
		"label": "Registrati"
	},
];

export default function App() {

	const dispatch = useAppDispatch()

	const generalSettings = useAppSelector((state) => state.general)
	const userState = useAppSelector((state) => state.user)
	const movieListState = useAppSelector((state) => state.movieList)

	useEffect(() => {

		// Listner sull'auth dell'utente
		onAuthStateChanged(auth, (currentUser) => {
			if (!currentUser) {
				dispatch(logoutRequest())
			}
		})

	}, [])

	useEffect(() => {
		if (userState.data.docId !== "") {

			// dispatch(setAppIsLoading(true))

			// Listener sulla movielist utente, nello useffect con return per evitare infinite loop.
			const qMovieList = query(collection(db, "users", userState.data.docId, 'movieList'), orderBy("added_on", "desc"));
			const unsubscribeMovieList = onSnapshot(qMovieList, snapshot => {

				// dispatch(setAppIsLoading(false))

				// Aggiorno lo store di redux
				if (snapshot.docs[0]) {

					const docs = snapshot.docs;

					let storeList: any[] = [];

					docs.map((movie) => {
						let movieStored = movie.data();
						movieStored.id = movie.id;
						return storeList = [...storeList, movieStored];
					})

					// console.log('list firestore', storeList);
					dispatch(updateMovieList(storeList));
				} else {
					dispatch(resetMovieList())
				}

			})

			// Listener sulla lista amici
			const qFriendList = query(collection(db, "users", userState.data.docId, 'friendList'), orderBy("added_on", "desc"));
			const unsubscribeFriendList = onSnapshot(qFriendList, snapshot => {

				// Aggiorno lo store di redux
				if (snapshot.docs[0]) {

					const docs = snapshot.docs;

					let friendList: any[] = [];

					docs.map((friend) => {
						let userStored = friend.data();
						userStored.docId = friend.id;
						return friendList = [...friendList, userStored];
					})

					// console.log('lista amici': friendList)
					dispatch(updateFriendList(friendList));

				} else {
					dispatch(resetFriendList())
				}

			})

			// Listener sulle notifiche
			const qNotifications = query(collection(db, 'users', userState.data.docId, 'notifications'), orderBy("added_on", "desc"), limit(20));
			const unsubscribeNotifications = onSnapshot(qNotifications, snapshot => {

				let newNotificationsExists = false;

				// Aggiorno lo store di redux
				if (snapshot.docs[0]) {

					const docs = snapshot.docs;

					let notifications: any[] = [];

					docs.map((notification) => {
						let data = notification.data();
						data.docId = notification.id;

						// Se c'è una notifica nuova
						data.read === false && (newNotificationsExists = true)
						return notifications = [...notifications, data];
					})

					// console.log('lista amici': friendList)
					dispatch(updateNotifications(notifications));
					dispatch(newNotifications(newNotificationsExists));

				} else {
					dispatch(resetNotifications())
					dispatch(newNotifications(false));
				}

			})

			return () => {
				unsubscribeMovieList();
				unsubscribeFriendList();
				unsubscribeNotifications()
			}
		}
	}, [userState.data.docId])

	useEffect(() => {
		// Debug redux movielist
		// console.log('redux store', movieListState.movieList);
	}, [movieListState.movieList])

	useEffect(() => {

		/*
		// Query per aggiungere o rimuovere un field a tutti gli utenti
		const c = collection(db, "users");
		const unsubscribe = onSnapshot(c, snapshot => {
			snapshot.docs.map(user => {
				const ref = user.ref;
				const userData = user.data();
				const userNameLowercase = userData.name.toLowerCase();

				// Aggiungi field
				// updateDoc(ref, {newField: ''})
				updateDoc(ref, {signUpOn: serverTimestamp()})
				// updateDoc(ref, {nameLowercase: userNameLowercase})

				// Elimina un field
				// updateDoc(ref, {nameSearchable: deleteField()})


				// Rimuovi field
				// updateDoc(ref, {friends: deleteField()})
			})
		});
		return () => {
			unsubscribe();
		}
		 */

	}, [])

	return (
		<MantineProvider
			theme={{
				colorScheme: (generalSettings.themeSettings.colorScheme === 'light') ? 'light' : 'dark',
				primaryColor: 'orange',
			}}
			withGlobalStyles
			withNormalizeCSS
		>
			<Helmet
				titleTemplate="%s | Moviebook"
				defaultTitle="Moviebook"
			>
				<meta name="description" content="Crea la tua libreria personale di film da vedere, consigliane di nuovi agli amici e commenta con la community." />
			</Helmet>

			<NotificationsProvider autoClose={5000}>

				<Navbar links={userState.isLogged ? mainMenu : unsignedMenu}></Navbar>

				<ScrollToTop />
				<Routes>
					<Route path="/" element={userState.isLogged ? <Dashboard /> : <Navigate to="user/signin" />} />
					<Route path="user/recover-password" element={<UserRecoverPassword />} />
					<Route path="user/signin" element={!userState.isLogged ? <UserSignin /> : <Navigate to="/" />} />
					<Route path="user/signup" element={!userState.isLogged ? <UserSignup /> : <Navigate to="/" />} />
					<Route path="movie/:movieId" element={userState.isLogged ? <MovieDetail /> : <Navigate to="/" />}/>
					<Route path="movie/search" element={userState.isLogged ? <SearchMovie /> : <Navigate to="/" />} />
					<Route path="friends" element={userState.isLogged ? <Friends /> : <Navigate to="/" />}/>
					<Route path="friends/:username" element={userState.isLogged ? <FriendDetail /> : <Navigate to="/" />}/>
					<Route path="friends/search" element={userState.isLogged ? <SearchFriend /> : <Navigate to="/" />}/>
					<Route path="notifications" element={userState.isLogged ? <Notifications /> : <Navigate to="/" />}/>
					<Route path="watchlist" element={userState.isLogged ? <Watchlist title="La mia watchlist" /> : <Navigate to="/" />} />
					<Route path="recently-viewed" element={userState.isLogged ? <Watchlist variant="recently-viewed" title="Visti di recente" /> : <Navigate to="/" />} />
					<Route path="*" element={< NotFound />} />
				</Routes>

				<FooterSimple links={userState.isLogged ? mainMenu : unsignedMenu}></FooterSimple>

				<LoadingOverlay
					visible={generalSettings.appIsLoading}
					overlayBlur={5}
					style={{position: 'fixed'}}
				/>

			</NotificationsProvider>
		</MantineProvider>
	);
}
