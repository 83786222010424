import { AspectRatio, Skeleton, Group, SimpleGrid } from '@mantine/core';

export const SkeletonMovieDetail = () => {

  return (
    <>
      <Group mb="xl">
        <Skeleton height={50} circle />
        <Skeleton height={50} circle />
        <Skeleton height={20} width="20%" />
      </Group>

      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />

      <Group grow>
        <AspectRatio ratio={2/3} style={{maxWidth: "30%"}} mt="xl">
          <Skeleton />
        </AspectRatio>
        <AspectRatio ratio={3/2} style={{maxWidth: "70%"}} mt="xl">
          <Skeleton />
        </AspectRatio>
      </Group>
    </>
  )
}
