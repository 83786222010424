import {
  Title,
  Container,
  Group,
  Button, SimpleGrid, Text, Table, Loader,
  Textarea, Space, Divider, LoadingOverlay,
} from '@mantine/core';

import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom"
import { useAppSelector } from "../utils/hooks"
import { getUserFromDocId, getUserFromRef } from "../api/api-users"
import { AvatarUser } from "../components/molecules/AvatarUser"
import { BtnHandleFriend } from "../components/molecules/BtnHandleFriend"
import { SkeletonFriends } from "../components/molecules/SkeletonFriends"
import { Notification } from "../utils/types"
import { NotificationItem } from "../components/molecules/NotificationItem"
import { Spacer } from "../components/atoms/Spacer"
import { useForm } from "@mantine/form"
import { IconClipboardText } from "@tabler/icons"
import { addDoc, collection, getDocs, serverTimestamp, where, updateDoc, query } from "firebase/firestore"
import { db } from "../api/api-firebase"
import { idPhantom, idValerio } from "../utils/constants"
import { sendNotification } from "../api/api-notifications"

export function Notifications() {

  const [isLoading, setIsLoading] = useState(false)
  const [notificationsToRead, setNotificationsToRead] = useState(0)

  const notifications = useAppSelector((state) => state.notifications.notifications)
  const userState = useAppSelector((state) => state.user)

  const form = useForm({
    initialValues: {
      text: '',
    }
  })

  const sendGlobalNotification = async (values: any) => {

    // Creo una notifica per ogni utente
    const users = await getDocs(collection(db, "users"))

    // await Promise.all(
    //   users.docs.map(async user => {
    //     return await sendNotification(
    //       user.id,
    //       "basic",
    //       values.text
    //     )
    //   })
    // )
    //
    // console.log('inviato')

    // Creo una notifica solo per me
    // BASIC
    // await addDoc(collection(db, "users", userState.data.docId, "notifications"), {
    //   active: true,
    //   added_on: serverTimestamp(),
    //   read: false,
    //   text: values.text,
    //   type: "basic",
    // });

    // INVIO NOTIFICA DI RICHIESTA DI AMICIZIA
    // await addDoc(collection(db, "users", idPhantom, "notifications"), {
    //   active: true,
    //   added_on: serverTimestamp(),
    //   read: false,
    //   text: values.text,
    //   type: "friendshipRequest",
    //   friend_requester: idPhantom
    // });

    form.reset()

  }

  const markAllNotificationsAsRead = async () => {

    const notifications = await getDocs(query(collection(db, 'users', userState.data.docId, 'notifications'), where("read", "==", false)))

    await Promise.all(
      notifications.docs.map(async notification => {
        return await updateDoc(notification.ref, {read: true})
      })
    )
  }

  useEffect(() => {
    if (notifications) {
      // Conteggio delle nuove notifiche da leggere
      const areThereNotificationsToRead = notifications.filter(notificatiton => notificatiton.read === false)
      if (areThereNotificationsToRead) {
        setNotificationsToRead(areThereNotificationsToRead.length)
      } else {
        setNotificationsToRead(0)
      }
    }
  }, [notifications])

  useEffect(() => {
    // console.log(userState.data.docId)
  }, [userState])

  return (
    <Container size="sm">

      <Group position="apart" align="center">
        <Title order={6}>
          {
            notificationsToRead !== 0 ?
              notificationsToRead === 1 ?
                'Hai 1 nuova notifica' : `Hai ${notificationsToRead} nuove notifiche`
            : 'Nessuna nuova notifica da leggere'
          }
          </Title>
        {
          notificationsToRead !== 0 &&
            <Button
              variant="subtle"
              style={{ textAlign: 'right' }}
              onClick={() => markAllNotificationsAsRead()}
            >
              Segna tutte come lette
            </Button>
        }
      </Group>

      <Divider my="sm" variant="dotted"/>

      {
        (notifications && notifications.length > 0) ?
          <>
            {
              notifications.map((item, i) => (
                <NotificationItem key={item.docId} item={item}/>
              ))
            }
            <>
              <Space h="md"/>
              <Group position="right">

              </Group>
            </>
          </>
        :
          (notifications && notifications.length === 0) ?
            <Container style={{ textAlign: 'center' }}>
              <Title order={3}>Non ci sono notifiche da mostrare</Title>
            </Container>
        : <Container style={{ textAlign: 'center' }}>
            <Loader size="sm" color="gray" />
          </Container>
      }

      {
        userState.data.docId === idValerio &&
        <>
            <Space mt={500} />
            <hr />
            <form
              onSubmit={form.onSubmit((values) => {
                sendGlobalNotification(values)
              })}
            >
              <Textarea
                label="Invia a tutti una notifica"
                autosize
                maxLength={400}
                minRows={2}
                maxRows={4}
                mt="md"
                icon={<IconClipboardText size={14} />}
                required
                {...form.getInputProps('text')}
              />
              <Button
                type="submit"
                mt="xl"
                loading={isLoading}
              >
                Invia
              </Button>
            </form>
          </>
      }

    </Container>
  );
}
