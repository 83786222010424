import { AspectRatio, Skeleton, Group, SimpleGrid } from '@mantine/core'

export const SkeletonFriends = () => {

  return (
    <>
      <Group mb="xl" grow>
        <Group>
          <Skeleton height={50} circle />
          <Skeleton height={20} width="30%" />
        </Group>
        <Skeleton height={20} width="20%" />
      </Group>
    </>
  )
}
