import {
  createStyles,
  Card,
  Image,
  Text,
  Title,
  AspectRatio,
  Paper,
  Grid,
  Badge,
  Group,
  RingProgress
} from '@mantine/core';
import { apiImagePath } from '../../utils/constants'
import {
  IconMovie,
  IconThumbUp,
} from "@tabler/icons";
import { useAppSelector } from "../../utils/hooks"
import { useEffect, useState } from "react"
import { BtnAddOrEditMovie } from "../molecules/BtnAddOrEditMovie"
import { BtnRemoveMovie } from "../molecules/BtnRemoveMovie"
import { BtnMarkSeenState } from "../molecules/BtnMarkAsSeen"
import { toFixedNoRounding, secondsToDate } from "../../utils/utils"
import { Link } from "react-router-dom"
import { Friend, Movie, MoviePersonalDetails } from "../../utils/types"
import { getUserFromRef } from "../../api/api-users"
import Moment from "react-moment"

interface Props {
  movie: Movie,
  mobileView?: 'default' | 'compact'
}

export function CardMovie({ mobileView, movie }: Props) {

  const { id, poster, title, releaseDate, rating } = movie;
  const url = `/movie/${movie.id}`;

  const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      flexDirection: 'column',

      [theme.fn.smallerThan('sm')]: {
        paddingBottom: mobileView === 'compact' ? 0 : 16
      },
    },

    cardEditBtn: {
      position: 'absolute',
      right: 14,
      top: 14,
      zIndex: 1,
      cursor: 'pointer',
    },

    cardBadgeLeft: {
      position: 'absolute',
      left: 14,
      top: 14,
      zIndex: 10,

      [theme.fn.smallerThan('sm')]: {
        top: 7,
        left: 7,
      },
    },

    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: `${theme.spacing.sm}px ${theme.spacing.lg}px`,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
    },

    title: {
      fontFamily: theme.fontFamily,
      lineHeight: 1,
    },

    cardImageArea: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },

    cardDetail: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexWrap: 'wrap',

      [theme.fn.smallerThan('sm')]: {
        display: mobileView === 'compact' ? 'none' : 'block',
      },
    },

    voteUser: {
      borderRadius: '50%',
      position: 'absolute',
      right: 10,
      top: 10,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white
    },

    friendSection: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.dark[0]
    }
  }));
  const { classes } = useStyles();

  const movieListState = useAppSelector((state) => state.movieList)

  const [movieDetail, setMovieDetail] = useState<MoviePersonalDetails | null>(null); // Dettagli personali utente loggato

  const [inWatchlist, setInWatchlist] = useState(false)
  const [recommendedBy, setRecommendedBy] = useState<Friend | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {

    setIsLoading(true);

    // Controllo se il film è in watchlist e se è presente mi estraggo i dettagli del film
    const movieInWatchlist = movieListState.movieList && movieListState.movieList.find(movie => movie.id === id.toString());

    if (movieInWatchlist) {
      setInWatchlist(true)
      setMovieDetail(movieInWatchlist)
      setIsLoading(false);
    } else {
      setInWatchlist(false)
      setMovieDetail(null)
      setRecommendedBy(null)
      setIsLoading(false);
    }

  }, [movieListState.movieList])

  useEffect(() => {

    // Setto l'eventuale stato dell'utente che ha raccomandato il film
    if (movieDetail) {
      if (movieDetail.recommended_by) {
        setRecommendedBy({
          id: '',
          name: movieDetail.recommended_by
        })
      } else if (movieDetail.recommended_by_user) {
        getUserFromRef(movieDetail.recommended_by_user).then(user => {
          const userData: any = user.data;
          setRecommendedBy(userData)
        })
      }
    }

  }, [movieDetail])

  return (
    <Card
      withBorder
      className={classes.card}
    >
      <Card.Section
        className={classes.cardImageArea}
        component={Link}
        to={url || '/'}
      >
        <AspectRatio ratio={2 / 3}>
          <Image
            withPlaceholder
            placeholder={<IconMovie size={32}/>}
            src={(poster !== '') ? `${apiImagePath}/w500/${poster}` : ''}
            alt={title}
            height="100%"
          />
        </AspectRatio>
      </Card.Section>

      {
        recommendedBy &&
        <Badge
          className={classes.cardBadgeLeft}
          leftSection={<IconThumbUp size={12} style={{ position: 'relative', top: 1 }}/>}
          color="gray"
          size="sm"
          radius="sm"
          variant="filled"
        >
          {recommendedBy.name}
        </Badge>
      }

      {
        !isLoading &&
        <Card.Section
          p="lg"
          className={classes.cardDetail}
        >
          <Paper mb={16}>
            <Group position="apart" noWrap align="top">
              <Title order={6}>{title}</Title>
              <div style={{ minWidth: 50 }}>
                {
                  releaseDate &&
                  <Text align="right" size="xs" color="dimmed" mb={2}>{releaseDate.substring(0, 4)}</Text>
                }
                {
                  ((rating && !movieDetail) || (rating && movieDetail && !movieDetail.watched)) &&
                  <Text
                    align="right"
                    size="xs"
                    weight={500}
                    color="teal"
                  >
                    {toFixedNoRounding(rating, 2)} / 10
                  </Text>
                }
                {
                  (movieDetail && movieDetail.watched) &&
                  <Text
                    align="right"
                    size="xs"
                    weight={500}
                    color="blue"
                  >
                    {movieDetail.vote} / 10
                  </Text>
                }
              </div>
            </Group>
          </Paper>
          <Paper>
            {
              inWatchlist ?
                <>
                  {
                    (movieDetail && !movieDetail.watched) ?
                      <Grid columns={8}>
                        <Grid.Col span={2}>
                          <BtnRemoveMovie
                            id={id}
                            title={title}
                            onRemoved={() => setInWatchlist(false)}
                          />
                        </Grid.Col>

                        <Grid.Col span={6}>
                          <BtnMarkSeenState id={id} title={title} action="asSeen"/>
                        </Grid.Col>
                      </Grid>
                      :
                      <BtnMarkSeenState id={id} title={title} action="asUnseen"/>
                  }
                </>
                :
                <BtnAddOrEditMovie
                  action="add"
                  id={id}
                  title={title}
                  variant="button"
                  onAdded={() => setInWatchlist(true)}
                />
            }
          </Paper>

        </Card.Section>
      }

      {/*Layout con commento ulteriore*/}
      {
        movie.friendDetails &&
        <Card.Section
          px="lg"
          py="sm"
          withBorder
          className={classes.friendSection}
        >
          <Group noWrap>
            <RingProgress
              size={40}
              thickness={3}
              sections={[{ value: (movie.friendDetails.vote * 10), color: 'blue' }]}
              label={
                <Text weight={700} align="center" size="xs">{movie.friendDetails.vote}</Text>
              }
            />
            <div>
              <Text size="xs">Voto di {movie.friendDetails.friendName}</Text>
              <Text size="xs" color="dimmed">visto <Moment locale="it" fromNow>{secondsToDate(movie.friendDetails.added_on.seconds)}</Moment></Text>
            </div>
          </Group>
        </Card.Section>
      }

    </Card>
  );
}
