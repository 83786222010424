import React, { useEffect, useState } from "react";
import {
	Title,
	Group,
	Container,
	Avatar,
	Text, AspectRatio, Skeleton,
	Grid, createStyles
} from "@mantine/core";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { searchFriend } from "../api/api-users"
import { SkeletonFriends } from "../components/molecules/SkeletonFriends"
import { secondsToDate, sortMoviesBy } from "../utils/utils"
import { Movie } from "../utils/types"
import { CardMovieCarousel } from "../components/organisms/CardMovieCarousel"
import { getMovie } from "../api/api-movies"
import { Spacer } from "../components/atoms/Spacer"
import { BtnHandleFriend } from "../components/molecules/BtnHandleFriend"
import { useAppSelector } from "../utils/hooks"
import Moment from "react-moment"
import { BtnHandleFriendFavourite } from "../components/molecules/BtnHandleFriendFavourite"

const useStyles = createStyles((theme) => ({
	friendBarRt: {
		textAlign: 'right',

		[theme.fn.smallerThan('xs')]: {
			textAlign: 'left',
		},
	},
}));

export function FriendDetail() {

	let params = useParams();
	const {classes} = useStyles();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true)

	const [user, setUser] = useState<any | null>(null)
	const [watchList, setWatchList] = useState<Movie[] | null>(null)
	const [watchedList, setWatchedList] = useState<Movie[] | null>(null)

	const userState = useAppSelector((state) => state.user)
	const friendListStore = useAppSelector(state => state.friendList.friendList)

	useEffect(() => {
		// Listener sulla lista amici, se l'amico non è più in lista devo uscire dall'area
		if (user && friendListStore) {
			// Cerco se nella mia lista amcii è presente l'utente
			const findUserInFriendlist = friendListStore.find(friend => friend.docId === user.data.docId);

			// Se l'amico è in lista, controllo se la richiesta è in pending
			if (!findUserInFriendlist || findUserInFriendlist.inPending) {
				// Porto fuori l'utente
				navigate('/friends')
			}
		}
	}, [friendListStore])

	useEffect(() => {

		// Recupero dettagli utente
		const searchUser = async () => {
			if (params.username) {
				const user = await searchFriend(params.username)

				// Se è il proprio profilo, faccio redirect alla watchlist
				userState.data.docId === user.data.docId && navigate('/watchlist')
				// Se l'utente non è un amico, faccio redirect alla watchlist
				!user.friendList.includes(userState.data.docId) && navigate('/friends')

				setUser(user);

				// Film in watchlist
				// Filtro i film
				const userWatchlistFiltered = user.movieList.filter(movie => !movie.watched);
				// Ordino i film per data di aggiunta
				const userWatchlistSorted = sortMoviesBy(userWatchlistFiltered, 'addedOn', 'desc');
				// Ottengo i film
				const userWatchlist = await Promise.all(
					userWatchlistSorted
						.map(async movie => {
							return await getMovie(parseInt(movie.id))
						})
				);

				// Film già visti
				// Filtro i film
				const userWatchedlistFiltered = user.movieList.filter(movie => movie.watched);
				// Ordino i film per data di aggiunta
				const userWatchedlistSorted = sortMoviesBy(userWatchedlistFiltered, 'watchedOn', 'desc');
				// Ottengo i film
				const userWatchedlist = await Promise.all(
					userWatchedlistSorted
						.map(async movie => {
							const movieDetail = await getMovie(parseInt(movie.id))
							return {
								...movieDetail,
								friendDetails: {
									...movie,
									friendName: user.data.name
								}
							}
						})
				);

				// Aggiorno gli stati
				setWatchList(userWatchlist);
				setWatchedList(userWatchedlist);
			}
		}
		searchUser()

		setIsLoading(false)

	}, [])

  return (
		<>
			{
				(user && watchList && watchedList && !isLoading) ?
					<>
						<Container>
							<Group position="apart" align="center">
								<Group>
									<Avatar
										src={user.data.avatar}
										radius={100}
										size={100}
									/>
									<div>
										<Group mb="xs">
											<Title order={1}>{user.data.name}</Title>
											<BtnHandleFriendFavourite
												userFrom={userState.data.docId}
												userTo={user.data.docId}
											/>
										</Group>
										<BtnHandleFriend
											size="xs"
											userFrom={userState.data.docId}
											userTo={user.data.docId}
										/>
									</div>
								</Group>
								<div className={classes.friendBarRt}>
									<Text size="sm" color="dimmed">{watchList.length} film nella watchlist</Text>
									<Text size="sm" color="dimmed">{watchedList.length} film segnati come visti</Text>
									<Text size="sm">Utente da <Moment fromNow ago>{secondsToDate(user.data.signUpOn.seconds)}</Moment></Text>
								</div>
							</Group>
						</Container>

						<Spacer smallerXs={80} largerXs={50} />

						{
							watchedList.length > 0 &&
							<>
								<Container>
									<Group position="apart" align="center" mb="xl">
										<Title order={4}>Visti di recente da {user.data.name}</Title>
									</Group>
								</Container>

								<CardMovieCarousel
									id={2}
									movieList={watchedList}
									isLoading={isLoading}
								/>

								<Spacer smallerXs={80} largerXs={50} />
							</>
						}

						{
							watchList.length > 0 &&
								<>
									<Container>
										<Group position="apart" align="center" mb="xl">
											<Title order={4}>La watchlist di {user.data.name}</Title>
										</Group>
									</Container>

									<CardMovieCarousel
										id={1}
										movieList={watchList}
										isLoading={isLoading}
									/>

									<Spacer smallerXs={80} largerXs={50} />
								</>
						}

					</>
				:
					<Container>

						<SkeletonFriends />
						<Spacer smallerXs={50} largerXs={20} />

						{
							[...Array(2)].map((item: number, i: number) => (
								<Grid mb={50} key={i}>
									{
										[...Array(3)].map((item: number, ic: number) => (
											<Grid.Col key={ic} xs={12} md={4}>
												<Skeleton height={500} />
											</Grid.Col>
										))
									}
								</Grid>
							))
						}

					</Container>
			}
		</>
  )

}
