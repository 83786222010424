import {
  Avatar,
  Title,
  createStyles,
  Group,
  Loader,
  Text,
  TextProps,
  Space,
  Menu,
  UnstyledButton,
  Button, Divider
} from "@mantine/core"
import { Friend, Notification } from "../../utils/types"
import { IconBell, IconBookmark, IconDots, IconInfoCircle } from "@tabler/icons"
import { secondsToDate } from "../../utils/utils"
import { BtnHandleFriend } from "./BtnHandleFriend"
import { useAppSelector } from "../../utils/hooks"
import { collection, deleteDoc, doc, getDoc, getDocs, serverTimestamp, setDoc, updateDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../api/api-firebase"
import Moment from "react-moment"
import 'moment/locale/it';
import { Link } from "react-router-dom"
import { showNotification } from "@mantine/notifications"


const useStyles = createStyles((theme) => ({

  wrap: {
    borderBottomColor: theme.colors.dark[4],
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    position: 'relative',
    width: '100%',

    // '&:hover': {
    //   backgroundColor: theme.colors.dark[6],
    // }
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    borderRadius: '50%',
    backgroundColor: theme.colors.dark[6],

    borderColor: theme.colors.dark[4],
    borderWidth: 1,
    borderStyle: "solid",

    'img': {
      borderRadius: '50%',
    },
  },

  menu: {
    position: 'absolute',
    right: 26,
    paddingTop: 3
  },

  notificationBubble: {
    position: 'absolute',
    right: 6,
    borderRadius: '50%',
    backgroundColor: theme.colors.red,
    width: 7,
    height: 7
  },
}));

interface Props {
  item: Notification
}

export const NotificationItem = ({ item }: Props) => {

  const userState = useAppSelector((state) => state.user)
  const movieListState = useAppSelector((state) => state.movieList.movieList)

  const { docId, type, text, added_on, read } = item;
  const {classes} = useStyles();

  const [isLoading, setIsLoading] = useState(false)
  const [friend, setFriend] = useState<any | null>(null)
  const [isMovieInWatchlistYet, setIsMovieInWatchlistYet] = useState<null | boolean>(null)

  const refNotification = doc(db, 'users', userState.data.docId, 'notifications', docId);

  const removeNotification = async () => {
    setIsLoading(true);
    await deleteDoc(refNotification)
    setIsLoading(false);
  }

  const markAsReadNotification = async (marked: boolean) => {
    setIsLoading(true);
    await updateDoc(refNotification, {read: marked})
    setIsLoading(false);
  }

  const addMovieSuggested = async () => {

    setIsLoading(true);

    await removeNotification()

    const docRef = doc(db, "users", userState.data.docId, 'movieList', item.movieSuggested.movieId.toString());
    await setDoc(docRef, {
      'added_on': serverTimestamp(),
      'note': item.movieSuggested.note || '',
      'recommended_by': null,
      'recommended_by_user': doc(db, 'users', item.friend_requester),
      'watched': false,
      'watched_on': serverTimestamp()
    })

    showNotification({
      title: "Film aggiunto",
      message: `"${item.movieSuggested.title}" è stato aggiunto alla tua watchlist`,
      icon: <IconBookmark />,
    })

    setIsLoading(false)
  }

  useEffect(() => {

    // Prendo i dati dell'utente se esiste
    if (item.friend_requester) {
      const refFriend = doc(db, 'users', item.friend_requester);
      getDoc(refFriend).then(user => {
        setFriend(user.data());
      })
    }

  }, [])

  useEffect(() => {
    // Se è un notifica movieSuggested, controllo se il film è già presente nella watchlist
    if (item.type === 'movieSuggested') {
      if (movieListState) {
        const checkMovie = movieListState.some(movie => movie.id === item.movieSuggested.movieId.toString())
        setIsMovieInWatchlistYet(checkMovie)
      } else {
        setIsMovieInWatchlistYet(false)
      }
    }
  }, [movieListState])

  return (
    <Group
      px="sm"
      py="lg"
      className={classes.wrap}
    >
      <Group align="center" noWrap>

        <div className={classes.icon}>
          {
            friend ?
              <Avatar size={24} src={friend.avatar} />
            :
              <IconInfoCircle size={24} />
          }
        </div>

        <div style={{ paddingRight: 50 }}>

          <div>
            <Text>{text}</Text>
            <Text color="dimmed" size="xs">
              <Moment locale="it" fromNow>{secondsToDate(added_on.seconds)}</Moment>
            </Text>
          </div>

          {
            (type === 'friendshipRequest') &&
              <>
                <Space h="sm" />
                <div style={{ justifyContent: 'flex-start' }}>
                  <BtnHandleFriend
                    size="xs"
                    userFrom={userState.data.docId}
                    userTo={item.friend_requester}
                    onSubmit={() => removeNotification()}
                  />
                </div>
              </>
          }

          {
            item.linkTo &&
            <>
              <Space h="sm" />
              <Group>
                {
                  type === 'movieSuggested' &&
                    <>
                    {
                      isMovieInWatchlistYet ?
                        <Button
                          size="xs"
                          variant="filled"
                          disabled
                        >
                          Film già nella tua watchlist
                        </Button>
                      :
                        <>
                          <Button
                            size="xs"
                            variant="filled"
                            onClick={() => addMovieSuggested()}
                          >
                            Accetta
                          </Button>
                          <Button
                            size="xs"
                            variant="light"
                            onClick={() => removeNotification()}
                          >
                            Rifiuta
                          </Button>
                        </>
                    }

                      <Divider orientation="vertical" variant="dotted" />

                    </>
                }
                {
                  (type === 'movieWatched' || type === 'movieSuggested') &&
                    <>
                      <Button
                        size="xs"
                        variant="outline"
                        component={Link}
                        to={item.linkTo}
                        onClick={() => markAsReadNotification(true)}
                      >
                        Vedi scheda film
                      </Button>
                    </>
                }
              </Group>
            </>
          }

        </div>

      </Group>

      <div className={classes.menu}>
        <Menu withArrow position="left">
          <Menu.Target>
            <UnstyledButton>
              <IconDots size={14} />
            </UnstyledButton>
          </Menu.Target>

          <Menu.Dropdown style={{ minWidth: 200 }}>

            {
              read ?
                <Menu.Item
                  onClick={() => markAsReadNotification(false)}
                  disabled={isLoading}
                >
                  Segna come da leggere
                </Menu.Item>
              :
                <Menu.Item
                  onClick={() => markAsReadNotification(true)}
                  disabled={isLoading}
                >
                  Segna come letta
                </Menu.Item>
            }

            <Menu.Item
              color="red"
              onClick={() => removeNotification()}
              disabled={isLoading}
            >
              Elimina
            </Menu.Item>

          </Menu.Dropdown>
        </Menu>
      </div>

      {
        !read && <span className={classes.notificationBubble} />
      }

    </Group>
  )
}
