import API from "../api/api"
import { Movie } from "../utils/types";
import ls from 'localstorage-slim';

const version = 3
const language = "language=it"
const region = "region=IT"


// Get movie externals + modeler
const fetchMoviePopular = async (pageReq: number) => {
  const res = await API.get(`/${version}/movie/popular?page=${pageReq}&${language}`)
  return res.data
}
const fetchMovieInTheatres = async (pageReq: number) => {
  const res = await API.get(`/${version}/movie/now_playing?page=${pageReq}&${language}&${region}`)
  return res.data
}

export const getMovieExt = async (type: 'popular' | 'inTheatres', pageReq = 1) => {

  let movies;

  switch (type) {
    case 'popular' :
      movies = await fetchMoviePopular(pageReq);
      break;
    case 'inTheatres' :
      movies = await fetchMovieInTheatres(pageReq);
      break;
    default :
      movies = await fetchMoviePopular(pageReq);
  }

  // Modeler - Type movie
  let movieArr: Movie[] = [];

  movies.results.map((movie: any) => (
    movieArr.push({
      id: movie.id,
      poster: movie.poster_path,
      title: movie.title,
      rating: movie.vote_average !== 0 ? movie.vote_average : null,
      releaseDate: movie.release_date,
    })
  ))

  return {
    list: movieArr,
    page: movies.page,
    total_pages: movies.total_pages,
    total_results: movies.total_results
  };
}



// Search movie + modeler
const fetchSearchMovie = async (movieString: string) => {
  const res = await API.get(`/${version}/search/movie?query=${movieString}&${language}`)
  return res.data
}

export const searchMovie = async (movieString: string) => {

  const moviesFetched = await fetchSearchMovie(movieString);

  // Modeler - Type movie
  let movies: Movie[] = [];

  moviesFetched.results.map((movie: any) => (
    movies.push({
      id: movie.id,
      poster: movie.poster_path,
      title: movie.title,
      rating: movie.vote_average !== 0 ? movie.vote_average : null,
      releaseDate: movie.release_date
    })
  ))

  return movies;
}



// Get movie card minimal or movie detailed + modeler
const fetchMovie = async (movieId: number) => {
  const res = await API.get(`/${version}/movie/${movieId}?${language}`)
  return res.data
}
const fetchMovieDetailed = async (movieId: number) => {
  const res = await API.get(`/${version}/movie/${movieId}?append_to_response=videos,credits&${language}`)
  return res.data
}
const fetchMovieProviders = async (movieId: number) => {
  const res = await API.get(`/${version}/movie/${movieId}/watch/providers?${language}`)
  return res.data
}

export const getMovie = async (movieId: number, debug?: boolean) => {

  const lsMovies = ls.get('movies')
  let movie;

  if (lsMovies) {
    // C'è almeno un film nel localstorage
    debug && console.log('C\'è almeno un film nel localstorage')

    if (lsMovies instanceof Array) {

      // Controllo se c'è il film nel localStorage
      const checkMovieLs = lsMovies.some(movie => movie.id === movieId)

      if (checkMovieLs) {
        // Il film è nel localstorage, lo recupero
        debug && console.log('Il film è nel localstorage')

        const searchMovie = lsMovies.filter(movie => movie.id === movieId)
        movie = searchMovie[0];
        debug && console.log('film nel localstorage: ', movie);

      } else {
        // Il film non è nel localstorage, lo fetcho
        debug && console.log('Il film non è nel localstorage')

        movie = await fetchMovie(movieId);
        lsMovies.push(movie);
      }

      // Aggiorno il localstorage
      ls.set('movies', lsMovies, { ttl: 86400 }); // 1 day ttl
      debug && console.log('localstorage aggiornato: ', lsMovies)

    }

  } else {

    debug && console.log('Nessun film nel localstorage, aggiungo il primo')

    // Non ci sono film nel localstorage
    let movies = [];
    movie = await fetchMovie(movieId);
    movies.push(movie);

    ls.set('movies', movies, { ttl: 86400 }); // 1 day ttl
    debug && console.log('localstorage aggiornato: ', ls.get('movies'))
  }

  debug && console.log('Il movie finale: ', movie);

  // Return del movie
  return {
    id: movie.id,
    poster: movie.poster_path,
    title: movie.title,
    rating: movie.vote_average !== 0 ? movie.vote_average : null,
    releaseDate: movie.release_date
  }
}

export const getMovieDetailed = async (movieId: number) => {

  const movie = await fetchMovieDetailed(movieId);
  const providers = await fetchMovieProviders(movieId);

  // console.log(movie);

  // Return del movie
  return {
    id: movie.id,
    title: movie.title,
    genre: (movie.genres.length > 0) ? movie.genres[0].name : null,
    genres: movie.genres,
    overview: movie.overview,
    poster: movie.poster_path,
    backdrop: movie.backdrop_path,
    rating: movie.vote_average !== 0 ? movie.vote_average : null,
    releaseDate: movie.release_date,
    runtime: movie.runtime,
    trailer: (movie.videos.results.length > 0) ? movie.videos.results[0].key : null,
    providers: providers.results,
    budget: movie.budget !== 0 ? movie.budget : null,
    revenue: movie.revenue !== 0 ? movie.revenue : null,
    production_companies: movie.production_companies
  }
}
