export const toFixedNoRounding = (num: number, length: number) => {
  return Number(num.toString().slice(0, (num.toString().indexOf(".")) + length))
}

export const formatNumber = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export const votesRange = () => {
  let votes = [];
  for (let i = 1; i <= 10; i += 0.5) {
    votes.push({
      value: i, label: i
    });
  }
  return votes;
}

export const sortMoviesBy = (array: any[], by: 'addedOn' | 'watchedOn' | 'date' | string, order: 'asc' | 'desc' | string) => {

  array.sort((a: any, b: any) => {

    let paramA;
    let paramB;

    switch (by) {
      case 'addedOn' :
        paramA = a.added_on
        paramB = b.added_on
        break;
      case 'watchedOn' :
        paramA = a.watched_on
        paramB = b.watched_on
        break;
      default :
        paramA = new Date(a.releaseDate)
        paramB = new Date(b.releaseDate)
    }

    if (paramA === paramB) {return 0;}
    return (order === 'asc') ? paramA > paramB ? 1 : -1 : paramA < paramB ? 1 : -1;

  })

  return array;

}

export const sortUserBy = (array: any[], by: 'name', order?: 'asc' | 'desc', favouriteFirst?: boolean) => {

  // Gli array da ciclare, possono essere più di uno
  let arrays: any[] = array;

  // L'array finale
  let arraysSorted: any[] = [];

  if (favouriteFirst) {

    // Splitto gli amici favourite da quelli non favourite
    let friends: any[] = [];
    let friendsFavourites: any[] = [];

    array.map(friend => {
      if (friend.personalInfo.favourite) {
        friends = [...friends, friend]
      } else {
        friendsFavourites = [...friendsFavourites, friend]
      }
    })

    // Ridefinisco gli array da ciclare
    arrays = [friends, friendsFavourites]

  }

  arrays.map(array => {
    const arraySorted = array.sort((a: any, b: any) => {

      let paramA;
      let paramB;

      switch (by) {
        default :
          paramA = a.data.nameLowercase
          paramB = b.data.nameLowercase
      }

      return (order === 'desc') ? paramA < paramB ? 1 : -1 : paramA > paramB ? 1 : -1;

    })
    arraysSorted = [...arraysSorted, arraySorted]
  })


  return [].concat.apply([], arraysSorted)

}

export const secondsToDate = (seconds: any) => {
  return new Date(seconds*1000)
}
