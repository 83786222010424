import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Overlay,
} from '@mantine/core';
import {useState} from "react";
import {auth} from "../api/api-firebase";
import {sendPasswordResetEmail} from "firebase/auth";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "@mantine/form";

export function UserRecoverPassword() {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email non valida'),
    },
  });

  const sendPasswordReset = async (values: any) => {

    setIsLoading(true);

    try {
      await sendPasswordResetEmail(
        auth,
        values.email
      );
      setIsLoading(false);
      navigate('/user/signin');
    } catch (err: any) {
      console.error(err)
      setIsLoading(false);
    }
  }

  return (
    <Container>

      <Title align="center" order={1}>Recupera password</Title>

      <Container size={420}>

        <form onSubmit={form.onSubmit((values) => sendPasswordReset(values))}>
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">

            {isLoading && <Overlay opacity={0.3} color="#000"/>}

            <TextInput label="Email" required {...form.getInputProps('email')} />

            <Button type="submit" fullWidth mt="xl" loading={isLoading && true}>Richiedi password</Button>

            <Group position="apart" mt="md">
              <Text component={Link} variant="link" size="sm" to="/user/signin">Torna al login</Text>
            </Group>

          </Paper>
        </form>

      </Container>
    </Container>
  );
}
