import { Friend, Movie, Notification } from "../utils/types";
import {
  collection,
  serverTimestamp,addDoc
} from "firebase/firestore"
import { db } from "./api-firebase"



export const sendNotification = async (
  userId: string,
  type: Notification['type'],
  text: string,
  friend_requester?: string | null,
  linkTo?: string | null,
  movieSuggested?: any | null
) => {

  return await addDoc(collection(db, 'users', userId, 'notifications'), {
    added_on: serverTimestamp(),
    read: false,
    text: text,
    type: type,
    friend_requester: friend_requester || null,
    linkTo: linkTo || null,
    movieSuggested: movieSuggested || null
  })

}
