import { useEffect, useState } from "react";
import {
	Title,
	Button,
	Group,
	Container,
	SimpleGrid,
	Text,
	createStyles, Divider, Select, Skeleton,
} from "@mantine/core";
import { getMovie } from "../api/api-movies"
import { NavLink } from "react-router-dom";
import { CardMovieList } from "../components/organisms/CardMovieList"
import { useAppDispatch, useAppSelector } from "../utils/hooks"
import { sortMoviesBy } from "../utils/utils"
import { setWatchlistOrder, setWatchlistRecentlyViewedOrder } from "../store/reducers/general"
import { useMediaQuery } from "@mantine/hooks"

interface Props {
	variant?: string;
	title: string;
}

export function Watchlist({variant, title}: Props) {

  const [isLoading, setIsLoading] = useState(true)
  const [movieList, setMovieList] = useState<any[] | null>(null)

	const movieListState = useAppSelector((state) => state.movieList)
	const generalSettings = useAppSelector((state) => state.general)
	const dispatch = useAppDispatch();

	const useStyles = createStyles((theme) => ({
		root: {
			paddingTop: 80,
			paddingBottom: 80,
		},

		control: {
			[theme.fn.smallerThan('sm')]: {
				width: '100%',
			},
		},

		mobileImage: {
			[theme.fn.largerThan('sm')]: {
				display: 'none',
			},
		},

		desktopImage: {
			[theme.fn.smallerThan('sm')]: {
				display: 'none',
			},
		},
	}));

	const { classes } = useStyles();
	const smallScreen = useMediaQuery('(max-width: 768px)');

	useEffect(() => {

		if (movieListState.movieList) {

			setIsLoading(true);

			const updateMovieList = async () => {

				const currentMovieList = await Promise.all(
					movieListState.movieList!
						.filter(movie => movie.watched === (variant === 'recently-viewed'))
						.map(async movie => {
							const movieFetched = await getMovie(parseInt(movie.id))
							return {
								...movieFetched,
								added_on: movie.added_on,
								watched_on: movie.watched_on,
							}
						})
				);

				// L'ordinamento della lista lo prendo da redux
				const orderDefault = (variant === 'recently-viewed') ? generalSettings.watchlistRecentlyViewedOrder : generalSettings.watchlistOrder;

				const orderDefaultValues = orderDefault ? orderDefault.split('|') : ['date', 'desc'];
				const by = orderDefaultValues[0];
				const order = orderDefaultValues[1];

				const movieListSorted = sortMoviesBy(currentMovieList, by, order);

				setMovieList(movieListSorted);
			}

			// Se ci sono film nella movielist, popola lo stato
			updateMovieList()

			setIsLoading(false);

		}
	}, [movieListState.movieList, variant]);

	const sortMovies = (value: string | null) => {
		if (value && movieList) {

			// Aggiorno lo store redux per mantenere salvati i settaggi della select
			if (variant === 'recently-viewed') {
				dispatch(setWatchlistRecentlyViewedOrder(value));
			} else {
				dispatch(setWatchlistOrder(value));
			}

			const values = value.split('|');
			const by = values[0];
			const order = values[1];

			const oldMovieList = [...movieList];
			const movieListSorted = sortMoviesBy(oldMovieList, by, order)

			setMovieList(movieListSorted)
		}
	}

	const selectSortOptions = () => {

		const optionsDefault = [
			// {value: 'date|asc', label: 'Data di uscita crescente'},
			{value: 'date|desc', label: 'Data di uscita'}
		];

		return (variant === 'recently-viewed') ?
			 [
				// {value: 'watchedOn|asc', label: 'Data di visualizzazione crescente'},
				{value: 'watchedOn|desc', label: 'Data di visualizzazione'},
				 ...optionsDefault
			]
		:
			[
				// {value: 'addedOn|asc', label: 'Data di aggiunta crescente'},
				{value: 'addedOn|desc', label: 'Data di aggiunta'},
				...optionsDefault
			]
	}

  return (
		<>

			{
				(movieList && movieList.length > 0) ?
					<>
						<Container mb="xl">

							<Group position="apart" align="center">
								<Title order={4}>{title}</Title>
								<Button variant="outline" component={NavLink} to="/movie/search">Aggiungi film</Button>
							</Group>

							<Divider my="sm" variant="dotted"/>

							<Group position="apart" align="center">
								<Text size="sm">{movieList.length} film nella lista</Text>
								<Select
									size="xs"
									onChange={(value) => sortMovies(value)}
									value={variant === 'recently-viewed' ? generalSettings.watchlistRecentlyViewedOrder : generalSettings.watchlistOrder}
									data={selectSortOptions()}
								/>
							</Group>

						</Container>

						<CardMovieList
							movieList={movieList}
							isLoading={isLoading}
							mobileView="compact"
						/>
					</>
				: (movieList && movieList.length === 0) ?
					<Container style={{textAlign: 'center'}}>
						<SimpleGrid>
							<div>
								{
									!variant ?
										<>
											<Title order={1}>Nessun film nella tua watchlist</Title>
											<Text color="dimmed" size="lg">
												Ancora non hai aggiunto nessun film alla watchlist.
											</Text>
											<Button
												component={NavLink}
												to="/movie/search"
												variant="outline"
												size="md"
												mt="xl"
												className={classes.control}
											>
												Aggiungi un nuovo film
											</Button>
										</>
										:
										<>
											<Title order={1}>Nessun film in questa lista</Title>
											<Text color="dimmed" size="lg">
												Non ci sono film nella lista selezionata.
											</Text>
											<Button
												component={NavLink}
												to="/watchlist"
												variant="outline"
												size="md"
												mt="xl"
												className={classes.control}
											>
												Vai alla tua watchlist
											</Button>
										</>
								}
							</div>
						</SimpleGrid>
					</Container>
				:
					<Container>
						<SimpleGrid cols={smallScreen ? 2 : 4}>
							{
								[...Array(8)].map((item: number, i: number) => (
									<Skeleton key={i} height={smallScreen ? 200 : 360}/>
								))
							}
						</SimpleGrid>
					</Container>
			}
		</>
  )

}
