import {
  IconShare,
  IconBrandWhatsapp,
  IconBrandTelegram,
  IconClipboard, IconClipboardText, IconMailFast
} from "@tabler/icons"
import { Badge, Button, Modal, Overlay, Textarea, TextInput, Select, Group, Divider } from "@mantine/core"
import { useEffect, useState } from "react"
import { useForm } from "@mantine/form"
import { useAppSelector } from "../../utils/hooks"
import { rootUrl } from "../../utils/constants"
import { useClipboard } from "@mantine/hooks"
import { AutocompleteUsers } from "./AutocompleteUsers"
import { sendNotification } from "../../api/api-notifications"
import { showNotification } from "@mantine/notifications"

interface Props {
  id: number; // Id movie
  title: string;
}

export const BtnShareMovie = ({ id, title }: Props) => {

  const [isLoading, setIsLoading] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)

  const userState = useAppSelector((state) => state.user)

  const clipboard = useClipboard({ timeout: 3000 });

  const form = useForm({
    initialValues: {
      note: '',
      recommended_by_user: '',
    }
  });

  const url = `${rootUrl}/movie/${id}`
  const message = `Hai già visto "${title}"? Aggiungilo alla tua watchlist su Moviebook!`

  const sendMovieSuggest = async (values: any) => {

    setIsLoading(true)

    const text = values.note ?
      `${userState.data.name} ti consiglia di aggiungere "${title}" alla tua watchlist. Ti annota che: "${values.note}"`
      :
      `${userState.data.name} ti consiglia di aggiungere "${title}" alla tua watchlist`

    await sendNotification(
      values.recommended_by_user,
      "movieSuggested",
      text,
      userState.data.docId,
      `/movie/${id}`,
      {
        movieId: id,
        title: title,
        note: values.note
      }
    )

    showNotification({
      title: "Film inviato",
      message: `"${title}" è stato inviato al tuo amico`,
      icon: <IconMailFast />,
    })

    setModalOpened(false)
    setIsLoading(false)
    form.reset()

  }

  return (
    <>
      <Button
        loading={isLoading}
        variant="default"
        size="xs"
        rightIcon={<IconShare size={14} />}
        onClick={() => {
          setModalOpened(true)
        }
      }
      >
        Consiglia
      </Button>

      <Modal
        opened={modalOpened}
        centered
        size="md"
        onClose={() => {
          setModalOpened(false)
          form.reset()
        }}
        title={`Consiglia "${title}" ad un amico`}
      >
        {isLoading && <Overlay opacity={0.3} color="#000"/>}

        <form
          onSubmit={form.onSubmit((values) => {
            sendMovieSuggest(values)
          })}>

          <AutocompleteUsers
            onSubmit={(user: any) => {
              form.setFieldValue("recommended_by_user", user.id)
            }}
            onSearching={() => {
              form.setFieldValue("recommended_by_user",'')
            }}
            searchOnlyFriends
          />

          <Textarea
            label="Aggiungi una nota"
            autosize
            maxLength={400}
            minRows={2}
            maxRows={4}
            mt="md"
            icon={<IconClipboardText size={14} />}
            {...form.getInputProps('note')}
          />

          <Button
            type="submit"
            fullWidth
            mt="xl"
            loading={isLoading && true}
            disabled={!form.values.recommended_by_user}
          >
            Invia consiglio
          </Button>

        </form>

        <Divider my="lg" label="Oppure condividi esternamente" labelPosition="center" />

        <Group>

          <Button
            component="a"
            variant="default"
            rightIcon={<IconBrandWhatsapp size={14} />}
            href={`whatsapp://send?text=${message} ${url}`}
            target="_blank"
          >
            Whatsapp
          </Button>

          <Button
            component="a"
            variant="default"
            rightIcon={<IconBrandTelegram size={14} />}
            href={`tg://msg?text=${message} ${url}`}
            target="_blank"
          >
            Telegram
          </Button>

          <Button
            variant="default"
            rightIcon={<IconClipboard size={14} />}
            onClick={
              () => {
                clipboard.copy(`${message} ${url}`)
              }
            }
          >
            {clipboard.copied ? 'Copiato' : 'Copia in clipboard'}
          </Button>

        </Group>

      </Modal>
    </>
  )

}
