import {
  Title,
  Text,
  Container,
  Group,
  LoadingOverlay,
  Image,
  BackgroundImage,
  Grid,
  RingProgress,
  Divider,
  Overlay, useMantineTheme, createStyles, Modal,
  AspectRatio,
  Paper,
  Tooltip,
  Blockquote, Table
} from '@mantine/core';
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getMovieDetailed } from "../api/api-movies"
import { getUserFromRef } from "../api/api-users"
import { IconCalendarStats, IconClipboardText, IconClockHour2, IconMovie, IconThumbUp, IconPlayerPlay, IconMasksTheater } from "@tabler/icons"
import { useAppSelector } from "../utils/hooks"
import { BtnAddOrEditMovie } from "../components/molecules/BtnAddOrEditMovie"
import { BtnRemoveMovie } from "../components/molecules/BtnRemoveMovie"
import { BtnMarkSeenState } from "../components/molecules/BtnMarkAsSeen"
import { apiImagePath } from "../utils/constants"
import { formatNumber, toFixedNoRounding } from "../utils/utils"
import { AvatarUser } from "../components/molecules/AvatarUser"
import { ListProviders } from "../components/molecules/ListProviders"
import { ListSimpleItem } from "../components/molecules/ListSimpleItem"
import { SkeletonMovieDetail } from "../components/molecules/SkeletonMovieDetail"
import { Friend, Movie } from "../utils/types"
import { useMediaQuery } from "@mantine/hooks"
import { Helmet } from "react-helmet"
import { BtnShareMovie } from "../components/molecules/BtnShareMovie"
import { collection, getDoc, getDocs, query, where, documentId } from "firebase/firestore"
import { db } from "../api/api-firebase"

const useStyles = createStyles(theme => ({
  trailerBox: {
    height: '100%',
    backgroundSize: 'cover',
    backgroundColor: theme.colors.gray[4],
    position: "relative",

    [theme.fn.smallerThan('sm')]: {
      height: 150
    },
  },

  trailerBoxOverlay: {
    display: "flex",
    alignItems: "center",
    height: '100%',
    justifyContent: "center",
    cursor: "pointer",
  },

  trailerBoxIcon: {
    border: 3,
    borderStyle: 'solid',
    borderColor: theme.colors.gray[4],
    borderRadius: '50%',
    padding: '10px',
    zIndex: 10,
    color: theme.colors.gray[4]
  },

  trailerBoxOverlayItem: {
    zIndex: 5
  },
}))

export function MovieDetail() {

  let params = useParams();
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const largeScreen = useMediaQuery('(min-width: 768px)');

  const [isLoading, setIsLoading] = useState(true)
  const [inWatchlist, setInWatchlist] = useState<boolean | undefined>(undefined)
  const [modalOpened, setModalOpened] = useState(false)

  const [recommendedBy, setRecommendedBy] = useState<Friend | null>(null)
  const [seenByList, setSeenByList] = useState<any[] | null>(null)

  // Movie è il film completo così come viene restituot da themovieDb ma con i valori che prendo io
  // MovieDetail è il film con i contenuti inseriti dall'utente
  const [movie, setMovie] = useState<Movie | undefined>(undefined)
  const [movieDetail, setMovieDetail] = useState<any | null>(null)

  const movieListState = useAppSelector((state) => state.movieList)
  const friendListStore = useAppSelector(state => state.friendList.friendList)

  useEffect(() => {

    const getMovie = async () => {
      if (params.movieId) {
        const movie = await getMovieDetailed(parseInt(params.movieId))
        setMovie(movie)
      }
    }
    getMovie()

  }, [])

  useEffect(() => {
    if (friendListStore) {

      // Ottengo la lista degli amici che hanno visto il film
      const getSeenByList = async () => {

        // Array finale di amici che hanno visto il film
        let friendsArr: any[] = [];

        await Promise.all(
          friendListStore
            .filter(user => (!user.inPending && user.isAccepted))
            .map(async friend => {
              const q = query(
                collection(db, "users", friend.docId, "movieList"),
                where(documentId(), "==", params.movieId),
                where("watched", "==", true)
              )
              const res = await getDocs(q);

              // L'amico ha visto il film
              if (res.docs.length > 0) {
                const filmDetail = res.docs[0].data()
                let userFromRef = await getUserFromRef(friend.docId, true) // Dettaglio utente
                userFromRef.vote = filmDetail.vote // Voto dato dall'utente
                friendsArr = [...friendsArr, userFromRef] // Aggiungo l'utente all'array
              }
            })
        )
        friendsArr.length > 0 && setSeenByList(friendsArr);
      }
      getSeenByList();
    }

  }, [friendListStore])

  useEffect(() => {

    // Controllo se il film è in watchlist e se è presente mi estraggo i dettagli del film
    if (movie) {
      const movieInWatchlist = movieListState.movieList && movieListState.movieList.find(movieItem => movieItem.id === movie.id.toString());

      if (movieInWatchlist) {
        setInWatchlist(true)
        setMovieDetail(movieInWatchlist)
      } else {
        setInWatchlist(false)
        setMovieDetail({})
        setRecommendedBy(null)
      }

      setIsLoading(false)
    }

  }, [movieListState.movieList, movie])

  useEffect(() => {

    // Setto l'eventuale stato dell'utente che ha raccomandato il film
    if (movieDetail) {
      if (movieDetail.recommended_by) {
        setRecommendedBy({
          id: '',
          name: movieDetail.recommended_by
        })
      } else if (movieDetail.recommended_by_user) {
        getUserFromRef(movieDetail.recommended_by_user).then(user => {
          const userData: any = user.data;
          setRecommendedBy(userData)
        })
      } else {
        setRecommendedBy(null)
      }
    }

  }, [movieDetail])

  // useEffect(() => {
  //   console.log(recommendedBy);
  // }, [recommendedBy])

  return (
    <Container>

      {
        movie ?
          <>
            <Helmet>
              <title>{movie.title}</title>
              <meta name="description" content={movie.overview || ''} />
              <meta property="og:image" content={(movie.poster !== '') ? `${apiImagePath}/original/${movie.poster}` : ''} />
            </Helmet>

            <Group position="apart" align="center" mb="xl" noWrap={largeScreen}>

              <div>
                <Group spacing="lg" noWrap>
                  <Group spacing="xs" noWrap>
                    {
                      movie.rating &&
                        <RingProgress
                          size={50}
                          thickness={4}
                          sections={[{ value: (movie.rating * 10), color: 'green' }]}
                          label={
                            <Text weight={700} align="center" size="sm">{toFixedNoRounding(movie.rating, 2)}</Text>
                          }
                        />
                    }
                    {
                      (inWatchlist && movieDetail.watched) &&
                      <RingProgress
                        size={50}
                        thickness={4}
                        sections={[{ value: (movieDetail.vote * 10), color: 'blue' }]}
                        label={
                          <Text weight={700} align="center" size="sm">{movieDetail.vote}</Text>
                        }
                      />
                    }
                  </Group>

                  <Title order={1} mb={10}>{movie.title}</Title>

                </Group>
              </div>

              {
                inWatchlist ?
                  <Group noWrap>
                    {
                      !movieDetail.watched ?
                        <>
                          <div>
                            <BtnRemoveMovie
                              id={movie.id}
                              title={movie.title}
                            />
                          </div>
                          <div>
                            <BtnMarkSeenState
                              id={movie.id}
                              title={movie.title}
                              action="asSeen"
                            />
                          </div>
                        </>
                        :
                        <>
                          <BtnMarkSeenState
                            id={movie.id}
                            title={movie.title}
                            action="asUnseen"
                          />
                        </>
                    }

                    <div>
                      <BtnAddOrEditMovie
                        action="edit"
                        variant="button"
                        id={movie.id}
                        title={movie.title}
                      />
                    </div>
                  </Group>
                  :
                  <div>
                    <BtnAddOrEditMovie
                      action="add"
                      variant="button"
                      id={movie.id}
                      title={movie.title}
                    />
                  </div>
              }
            </Group>

            <Divider my="sm" variant="dotted"/>

            <Group position="apart" align="center">

              <Group spacing="md">

                {
                  movie.releaseDate &&
                  <Group spacing="xs">
                    <IconCalendarStats size={16}/>
                    <Text size="sm">{movie.releaseDate.substring(0, 4)}</Text>
                  </Group>
                }

                <Divider orientation="vertical" variant="dotted"/>
                <Group spacing="xs">
                  <IconClockHour2 size={16}/>
                  <Text size="sm">{movie.runtime} minuti</Text>
                </Group>

                {/*<Divider orientation="vertical" variant="dotted"/>*/}
                {/*<Group spacing="xs">*/}
                {/*  <IconMasksTheater size={16}/>*/}
                {/*  <Text size="sm">{movie.genre}</Text>*/}
                {/*</Group>*/}

              </Group>

              <BtnShareMovie
                id={movie.id}
                title={movie.title}
              />

            </Group>

            <Divider my="sm" variant="dotted"/>

            <Grid grow mt="md">
              <Grid.Col md={4}>
                <Image
                  withPlaceholder
                  placeholder={<IconMovie size={32}/>}
                  src={(movie.poster !== '') ? `${apiImagePath}/original/${movie.poster}` : ''}
                  alt={movie.title}
                />
              </Grid.Col>
              <Grid.Col md={8} sm={1}>
                <BackgroundImage
                  className={classes.trailerBox}
                  src={(movie.backdrop !== '') ? `${apiImagePath}/original/${movie.backdrop}` : ''}
                >
                  {
                    movie.trailer &&
                      <div className={classes.trailerBoxOverlay} onClick={() => setModalOpened(true)}>
                        <IconPlayerPlay size={80} className={classes.trailerBoxIcon} />
                        <Overlay className={classes.trailerBoxOverlayItem} gradient={`linear-gradient(105deg, ${theme.black} 20%, #312f2f 50%, ${theme.colors.gray[4]} 100%)`}/>
                      </div>
                  }
                </BackgroundImage>
              </Grid.Col>
            </Grid>

            <Grid mt="md">

              <Grid.Col md={4} sm={1}>

                <Paper radius="lg" p="md" mb="md" withBorder>
                  {
                    (movieDetail && (recommendedBy || movieDetail.note)) ?
                      <>
                        {
                          recommendedBy &&
                            <>
                              <Text color="dimmed" mb="sm" weight={700} size="xs">Consigliato da</Text>
                              <AvatarUser src={recommendedBy.avatar || undefined} name={recommendedBy.name} />
                            </>
                        }
                        {
                          (recommendedBy && movieDetail.note) &&
                            <Divider mt="md" mb="sm" variant="dotted"/>
                        }
                        {
                          movieDetail.note &&
                          <>
                            <Blockquote>
                              {movieDetail.note}
                            </Blockquote>
                          </>
                        }
                      </>
                    :
                      <>
                      {
                        inWatchlist ?
                          <>
                            {
                              (movieDetail && !movieDetail.watched) ?
                                <>
                                  <Text color="dimmed" mb="sm" weight={700} size="xs">Aggiungi informazioni personali</Text>
                                  <Text color="dimmed" italic size="xs" mb="md">Puoi associare al film una persona che te l'ha consigliato e alcune note personali.</Text>
                                </>
                              :
                                <Text color="dimmed" mb="sm" weight={700} size="xs">Hai cambiato idea? Puoi sempre modificare la tua valutazione</Text>
                            }
                            <BtnAddOrEditMovie
                              action="edit"
                              variant="button"
                              buttonVariant="outline"
                              buttonSize="xs"
                              id={movie.id}
                              title={movie.title}
                            />
                          </>
                        :
                          <>
                            {
                              (movieDetail && !movieDetail.watched) ?
                                <>
                                  <Text color="dimmed" mb="sm" weight={700} size="xs">Non hai ancora visto questo film?</Text>
                                  <Text color="dimmed" italic size="xs" mb="md">Se aggiungi il film alla watchlist potrai associare alcune informazioni personali che troverai successivamente raccolte in questo box.</Text>
                                </>
                                :
                                  <Text color="dimmed" mb="sm" weight={700} size="xs">Hai cambiato idea? Puoi sempre modificare la tua valutazione</Text>
                            }
                            <BtnAddOrEditMovie
                              action="add"
                              variant="button"
                              buttonVariant="outline"
                              buttonSize="xs"
                              id={movie.id}
                              title={movie.title}
                            />
                          </>
                      }
                      </>
                  }

                </Paper>

                {
                  seenByList &&
                    <Paper radius="lg" p="md" withBorder>
                      <Text color="dimmed" mb="sm" weight={700} size="xs">Amici che lo hanno visto</Text>
                      <Table verticalSpacing={7} horizontalSpacing={0} >
                        <tbody>
                        {
                          seenByList.map((friend, i) => (
                            <tr key={friend.refId}>
                              <td align="left">
                                <AvatarUser
                                  src={friend.data.avatar || ''}
                                  name={friend.data.name || ''}
                                />
                              </td>
                              <td align="right">
                                <Text
                                  size="sm"
                                  color={'blue'}
                                >{friend.vote}</Text>
                              </td>
                            </tr>
                          ))
                        }
                        </tbody>
                      </Table>
                    </Paper>
                }
              </Grid.Col>

              <Grid.Col md={8} sm={1}>

                <Text size="sm" mb="xl">{movie.overview}</Text>

                <div>
                  {
                    movie.providers.IT &&
                    <>
                      {
                        movie.providers.IT.flatrate &&
                        <ListProviders providers={movie.providers.IT.flatrate} label="In streaming su" />
                      }
                      {
                        movie.providers.IT.buy &&
                        <ListProviders providers={movie.providers.IT.buy} label="Acquista su" />
                      }
                    </>
                  }
                  {movie.genre && <ListSimpleItem label="Genere" value={<Text size="sm">{movie.genre}</Text>} />}
                  {
                    movie.production_companies &&
                      <ListSimpleItem
                        label="Produzione"
                        value={
                          <Text size="sm" align="right">
                            {movie.production_companies.map((company: any) => (
                              <div key={company.id}>
                                {company.name}<br />
                              </div>
                            ))}
                          </Text>
                        }
                      />
                  }
                  {movie.budget && <ListSimpleItem label="Budget" value={<Text size="sm">{formatNumber(movie.budget)} $</Text>} />}
                  {movie.revenue && <ListSimpleItem label="Incassi" value={<Text size="sm">{formatNumber(movie.revenue)} $</Text>} />}
                </div>

              </Grid.Col>

            </Grid>

            <Modal
              opened={modalOpened}
              centered
              size="xl"
              onClose={() => setModalOpened(false)}
            >
              <AspectRatio ratio={16 / 9}>
                <iframe
                  width="100%"
                  src={`https://www.youtube-nocookie.com/embed/${movie.trailer}`}
                  title="Trailer"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </AspectRatio>
            </Modal>

          </>
        :
          <SkeletonMovieDetail />
      }

    </Container>
  );
}
