import { Container, Skeleton, SimpleGrid } from '@mantine/core';
import { Movie } from "../../utils/types"
import { CardMovie } from "./CardMovie"

interface Props {
  movieList: Movie[];
  isLoading: boolean;
  fluid?: boolean;
  mobileView?: 'default' | 'compact'
}

export function CardMovieList({ movieList, isLoading, fluid, mobileView }: Props) {

  const breakpoints = fluid ? [
    { maxWidth: 1920, cols: 6 },
    { maxWidth: 1440, cols: 5 },
    { maxWidth: 980, cols: 2 },
    { maxWidth: 600, cols: 1 },
  ] : [
    { maxWidth: 980, cols: 3 },
    { maxWidth: 768, cols: 2 },
    { maxWidth: 600, cols: 2 },
  ];

  return (
    <Container fluid={fluid && true}>
      <SimpleGrid
        cols={fluid ? 7 : 4}
        breakpoints={breakpoints}
      >
        {
          !isLoading ?
            movieList.map((movie: Movie) => (
              <CardMovie
                key={movie.id}
                movie={movie}
                mobileView={mobileView}
              />
            )) :
            [...Array(8)].map((item: number, i: number) => (
              <Skeleton key={i} height={450}/>
            ))
        }
      </SimpleGrid>
    </Container>
  );
}
