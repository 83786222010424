import {
  IconStar
} from "@tabler/icons"
import { UnstyledButton, createStyles } from "@mantine/core"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../utils/hooks"
import { friendToFavourite } from "../../api/api-users"

interface Props {
  size?: number,
  userFrom: string,
  userTo: string,
}

export const BtnHandleFriendFavourite = ({ userFrom, userTo, size }: Props) => {

  const [isLoading, setIsLoading] = useState(false)
  const [areFriends, setAreFriends] = useState(false)
  const [areFavourites, setAreFavourite] = useState(false)

  const friendListStore = useAppSelector(state => state.friendList.friendList)

  const useStyles = createStyles((theme) => ({
    star: {
      fill: areFavourites ? (theme.colorScheme === 'dark' ? theme.colors.dark[0] : 'black') : 'transparent',
      top: 3,
      position: 'relative'
    }
  }));

  const {classes} = useStyles();

  useEffect(() => {
    if (friendListStore) {

      setIsLoading(true);

      // Controllo se l'utente è nella lista
      const friend = friendListStore.find((user: any) => user.docId === userTo);

      if (friend) {
        setAreFriends(friend)
        setAreFavourite(friend.favourite || false)
        setIsLoading(false)
      } else {
        setAreFavourite(false)
      }
    }
  }, [friendListStore])

  const onClick = async () => {

    setIsLoading(true);

    await friendToFavourite(
      userFrom,
      userTo,
      areFavourites ? 'remove' : 'add'
    )

    setIsLoading(false)

  }

  return (
    <>
      {
        areFriends &&
          <UnstyledButton onClick={() => onClick()} disabled={isLoading}>
            <IconStar size={size || 22} className={classes.star}  />
          </UnstyledButton>
      }
    </>
  )

}
