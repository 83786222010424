import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  notifications: <any[] | null> null,
  newNotifications: <boolean> false
};

const userSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateNotifications(state, action) {
      state.notifications = action.payload;
    },
    newNotifications(state, action) {
      state.newNotifications = action.payload;
    },
    resetNotifications(state) {
      state.notifications = [];
    }
  },
});

export const { updateNotifications, newNotifications, resetNotifications } = userSlice.actions;

export default userSlice.reducer
