import { IconCheck, IconClipboardText, IconEdit, IconBookmark, IconUser } from "@tabler/icons"
import {
  Badge,
  Button,
  ButtonProps,
  Checkbox, Divider,
  Modal,
  Overlay, Paper,
  Select,
  Textarea,
  TextInput
} from "@mantine/core"
import { useEffect, useState } from "react"
import { useForm } from "@mantine/form"
import { collection, doc, getDocs, serverTimestamp, setDoc, updateDoc } from "firebase/firestore"
import { showNotification } from "@mantine/notifications"
import { db } from "../../api/api-firebase"
import { useAppSelector } from "../../utils/hooks"
import { votesRange } from "../../utils/utils"
import { AutocompleteUsers } from "./AutocompleteUsers"
import { getUserFromRef } from "../../api/api-users"
import movieList from "../../store/reducers/movieList"
import { sendNotification } from "../../api/api-notifications"

interface Props {
  action: 'add' | 'edit';
  id: number;
  title: string;
  variant: 'button' | 'icon';
  onAdded?: any;
  buttonVariant?: ButtonProps["variant"];
  buttonSize?: ButtonProps["size"];
  withoutIcon?: boolean;
}

export const BtnAddOrEditMovie = ({ id, title, action, onAdded, variant, buttonVariant, buttonSize, withoutIcon }: Props) => {

  const [isLoading, setIsLoading] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [movieDetail, setMovieDetail] = useState<any | null>(null)
  const [voteValues, setVoteValues] = useState<any[] | null>(null)

  // È lo stato che determina se sto cercando un utente reale oppure no
  const [searchingRecommendedRealUser, setSearchingRecommendedRealUser] = useState(false)
  //È lo stato che raccoglie i dettagli utente completi
  const [recommendedRealUser, setRecommendedRealUser] = useState<any | null>(null)

  const userState = useAppSelector((state) => state.user)
  const movieListState = useAppSelector((state) => state.movieList)
  const docRef = doc(db, "users", userState.data.docId, 'movieList', id.toString());

  const formUnwatched = useForm({
    initialValues: {
      note: '',
      recommended_by: '',
      recommended_by_user: '',
    }
  });

  const formWatched = useForm({
    initialValues: {
      vote: 0
    }
  });

  const addMovie = async (values: any) => {

    setIsLoading(true);

    const refRecommendedUser = values.recommended_by_user ? doc(db, 'users', values.recommended_by_user) : null;

    await setDoc(docRef, {
      'added_on': serverTimestamp(),
      'note': values.note || '',
      'recommended_by': values.recommended_by || null,
      'recommended_by_user': refRecommendedUser,
      // 'vote': 0,
      'watched': false,
      'watched_on': serverTimestamp()
    })

    showNotification({
      title: "Film aggiunto",
      message: `"${title}" è stato aggiunto alla tua watchlist`,
      icon: <IconBookmark />,
    })

    setModalOpened(false);
    setIsLoading(false);
    formUnwatched.reset();

    // Callback
    onAdded && onAdded()

  }

  const editMovie = async (values: any) => {

    setIsLoading(true);

    const refRecommendedUser = values.recommended_by_user ? doc(db, 'users', values.recommended_by_user) : null;

    await updateDoc(docRef, {
      'note': values.note || '',
      'recommended_by': values.recommended_by || null,
      'recommended_by_user': refRecommendedUser,
      // 'recommended_by_user': values.recommended_by_user || '',
    })

    showNotification({
      title: "Film aggiornato correttamente",
      message: `Le informazioni su "${title}" sono state aggiornate`,
      icon: <IconCheck />,
    })

    setModalOpened(false);
    setIsLoading(false);

  }

  const editMovieWatched = async (values: any) => {

    setIsLoading(true);

    await updateDoc(docRef, {
      'vote': values.vote,
    })

    showNotification({
      title: "Film aggiornato correttamente",
      message: `Le informazioni su "${title}" sono state aggiornate`,
      icon: <IconCheck />,
    })

    setModalOpened(false);
    setIsLoading(false);

  }

  const addMovieAsWatched = async (values: any) => {

    setIsLoading(true);

    await setDoc(docRef, {
      'added_on': serverTimestamp(),
      'note': '',
      'recommended_by': null,
      'recommended_by_user': null,
      'vote': values.vote,
      'watched': true,
      'watched_on': serverTimestamp()
    })

    showNotification({
      title: "Film aggiunto come visto",
      message: `"${title}" è stato aggiunto alla lista dei tuoi film visti`,
      icon: <IconCheck />,
    })

    setModalOpened(false);
    setIsLoading(false);
    formWatched.reset();

    // Invio notifica agli amici favourites / oppure a tutti
    const friends = await getDocs(collection(db, 'users', userState.data.docId, 'friendList'))

    await Promise.all(
      friends.docs.map(async user => {
        await sendNotification(
          user.id,
          "movieWatched",
          `${userState.data.name} ha aggiunto "${title}" tra i film visti e l'ha votato ${values.vote}`,
          userState.data.docId,
          `/movie/${id}`
        )
      })
    )

    // Callback
    onAdded && onAdded()

  }

  useEffect(() => {
    // Genero i possibili voti da 0 a 10 per la select
    setVoteValues(votesRange());
  }, [])

  useEffect(() => {

    // Controllo se il film è in watchlist
    const movie = movieListState.movieList && movieListState.movieList.find(movie => movie.id === id.toString());

    if (movie && movie.watched) {
      // Aggiorno i valori del formWatched
      formWatched.setValues({
        vote: movie.vote
      })
    }

    if (movie) {

      setMovieDetail(movie)

      // Aggiorno i valori del formUnwatched

      // Il film è raccomandato da un utente reale, popolo lo state e aggiorno il valore del form
      if (movie.recommended_by_user) {
        setSearchingRecommendedRealUser(true)
        getUserFromRef(movie.recommended_by_user).then(user => {
          if (user) {
            formUnwatched.setFieldValue("recommended_by_user", user.refId);
            setRecommendedRealUser(user.data)
          }
        })
      }

      movie.recommended_by &&
        formUnwatched.setFieldValue("recommended_by", movie.recommended_by);

      formUnwatched.setFieldValue("note", movie.note);

    } else {
      // Aggiorno i valori del formUnwatched
      formUnwatched.setValues({
        recommended_by: '',
        recommended_by_user: '',
        note: ''
      })
    }

  }, [movieListState.movieList])

  return (
    <>
      {
        variant === 'button' ?
          <Button
            loading={isLoading}
            fullWidth
            variant={buttonVariant || "filled"}
            size={buttonSize || "sm"}
            rightIcon={
              !withoutIcon ?
                action === 'edit' ? <IconEdit size={14}/> : <IconBookmark size={14} />
              : null
            }
            onClick={() => setModalOpened(true)}
          >
            {action === 'edit' ? "Modifica" : "Aggiungi"}
          </Button>
        :
          <Badge
            variant="filled"
            color="gray"
            size="sm"
            radius="sm"
            onClick={() => setModalOpened(true)}
          >
            {
              action === 'edit' ?
                <IconEdit size={12} style={{position:'relative', top: 1}} />
                :
                <IconBookmark size={12} style={{position:'relative', top: 1}} />
            }
          </Badge>
      }

      <Modal
        opened={modalOpened}
        centered
        onClose={() => setModalOpened(false)}
        title={
          action === 'add' ?
            `Aggiungi "${title}" alla tua watchlist`
          :
            `Modifica informazioni su "${title}"`
        }
      >
        {
          // Il primo form è per l'aggiunta o la modifica del film non visto,
          // il secondo è la modifica del film già visto e votato

          ((movieDetail && !movieDetail.watched) || action === 'add') ?
            <>
              <form
                onSubmit={formUnwatched.onSubmit((values) => {
                  (action === 'edit') ? editMovie(values) : addMovie(values)
                })}>

                {isLoading && <Overlay opacity={0.3} color="#000"/>}

                {
                  searchingRecommendedRealUser ?
                    <AutocompleteUsers
                      label="Consigliato da"
                      onSubmit={(user: any) => {
                        formUnwatched.setFieldValue("recommended_by_user", user.id)
                        setRecommendedRealUser(user)
                      }}
                      onSearching={() => {
                        formUnwatched.setFieldValue("recommended_by_user",'')
                        setSearchingRecommendedRealUser(true)
                        setRecommendedRealUser(null)
                      }}
                      defaultUser={recommendedRealUser || null}
                      searchOnlyFriends
                    />
                    :
                    <TextInput
                      label="Consigliato da"
                      maxLength={20}
                      icon={<IconUser size={14} />}
                      {...formUnwatched.getInputProps('recommended_by')}
                    />
                }

                <Checkbox
                  mt={10}
                  label="Cerca tra i tuoi amici"
                  defaultChecked={searchingRecommendedRealUser}
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      setSearchingRecommendedRealUser(true)
                      setRecommendedRealUser(null)
                      formUnwatched.setFieldValue("recommended_by", '');
                    } else {
                      setSearchingRecommendedRealUser(false)
                      setRecommendedRealUser(null)
                      formUnwatched.setFieldValue("recommended_by_user", '');
                    }
                  }}
                />

                <Textarea
                  label="Aggiungi una nota"
                  autosize
                  maxLength={400}
                  minRows={2}
                  maxRows={4}
                  mt="md"
                  icon={<IconClipboardText size={14} />}
                  {...formUnwatched.getInputProps('note')}
                />

                <Button
                  type="submit"
                  fullWidth
                  mt="xl"
                  loading={isLoading && true}
                  disabled={searchingRecommendedRealUser && !recommendedRealUser}
                >
                  {action === 'edit' ? "Modifica film" : "Aggiungi film"}
                </Button>

              </form>

              {
                action === 'add' &&
                  <>
                    <Divider my="lg" label="Hai già visto questo film?" labelPosition="center" />

                    <Paper shadow="sm" radius="md" p="sm" withBorder>
                      <form
                        onSubmit={formWatched.onSubmit((values) => {
                          addMovieAsWatched(values)
                        })}>

                        {isLoading && <Overlay opacity={0.3} color="#000"/>}

                        <Select
                          label="Assegna un voto da 1 a 10"
                          placeholder="Assegna voto"
                          data={voteValues}
                          {...formWatched.getInputProps('vote')}
                        />
                        <Button
                          type="submit"
                          fullWidth
                          mt="xl"
                          loading={isLoading && true}
                          variant="outline"
                        >
                          Segna come visto
                        </Button>

                      </form>
                    </Paper>
                  </>
              }

            </>
          :
            <form
              onSubmit={formWatched.onSubmit((values) => editMovieWatched(values))}>

              {isLoading && <Overlay opacity={0.3} color="#000"/>}

              <Select
                label="Voto da 1 a 10"
                placeholder="Modifica voto"
                data={voteValues}
                {...formWatched.getInputProps('vote')}
              />
              <Button
                type="submit"
                fullWidth
                mt="xl"
                loading={isLoading && true}
              >
                Modifica film
              </Button>

            </form>
        }
      </Modal>
    </>
  )

}
