import {combineReducers} from 'redux'
import userSlice from './user'
import generalSlice from './general'
import movieListSlice from "./movieList"
import friendList from "./friendList"
import notifications from "./notifications"

export default combineReducers({
	user: userSlice,
	general: generalSlice,
	movieList: movieListSlice,
	friendList: friendList,
	notifications: notifications
})
