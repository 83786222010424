import { Autocomplete, Loader, SelectItemProps, Group, Button, Grid } from "@mantine/core"
import { forwardRef, useEffect, useRef, useState } from "react"
import { searchFriends } from "../../api/api-users"
import { IconUserCheck, IconSwitchHorizontal } from "@tabler/icons"
import { AvatarUser } from "./AvatarUser"
import { useAppSelector } from "../../utils/hooks"

interface Props {
  onSubmit: any;
  onSearching: any;
  label?: string;
  defaultUser?: any | null;
  searchOnlyFriends?: boolean
}

interface ItemProps extends SelectItemProps {
  avatar: string;
  name: string;
  nameLowercase: string;
}

export const AutocompleteUsers = ({ onSubmit, onSearching, label, defaultUser, searchOnlyFriends }: Props) => {

  const timeoutRef = useRef<number>(-1);
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [isFounded, setIsFounded] = useState(false);

  const userState = useAppSelector((state) => state.user)

  const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, avatar, name, nameLowercase, id, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <AvatarUser
            src={avatar}
            name={name}
          />
        </Group>
      </div>
    )
  );

  const handleChange = (inputValue: string) => {

    setValue(inputValue);

    if (inputValue.length >= 3) {

      window.clearTimeout(timeoutRef.current);

      setIsLoading(true);
      setData([]);

      timeoutRef.current = window.setTimeout(async () => {

        const users = await searchFriends(
          inputValue,
          userState.data.name.toLowerCase(),
          (searchOnlyFriends ? userState.data.docId : undefined)
        );

        const data = users.map((user) => ({
          value: user.name,
          id: user.id,
          name: user.name,
          nameLowercase: user.nameLowercase,
          avatar: user.avatar
        }));

        setData(data);

        setIsLoading(false);

      }, 1000);

    }
  };

  useEffect(() => {
    if (defaultUser) {
      setIsFounded(true)
      setValue(defaultUser.name)
    }
  }, [defaultUser])

  return (
    <Grid align="flex-end">
      <Grid.Col span={9}>
        <Autocomplete
          value={value}
          data={data}
          label={label}
          placeholder={searchOnlyFriends ? 'Inserisci username amico' : 'Inserisci username'}
          onChange={handleChange}
          rightSection={isLoading ? <Loader size={16} /> : null}
          icon={<IconUserCheck size={14} />}
          itemComponent={AutoCompleteItem}
          filter={(value, item) => {
            return item.nameLowercase.includes(value.toLowerCase().trim())
          }}
          onItemSubmit={(item) => {
            setIsFounded(true)
            onSubmit(item)
          }}
          disabled={isFounded}
          withAsterisk
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <Button
          variant="light"
          fullWidth
          size="sm"
          disabled={!isFounded}
          onClick={() => {
            onSearching(false)
            setIsFounded(false)
          }}
        >
          <IconSwitchHorizontal size={18} />
        </Button>
      </Grid.Col>
    </Grid>
  )
}
