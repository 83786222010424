import axios from "axios";

const token_api3 = process.env.REACT_APP_TMDB_API3;
const token_api4 = process.env.REACT_APP_TMDB_API4;

export default axios.create({
	baseURL: "https://api.themoviedb.org",
	// withCredentials: true,
	// responseType: "json",
	headers: {
		'Content-Type': 'application/json;charset=utf-8',
		'Authorization': `bearer ${token_api4}`
	}
});
