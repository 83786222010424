import {
  TextInput,
  Title,
  Container,
  useMantineTheme,
  ActionIcon,
  Loader, LoadingOverlay, FocusTrap
} from '@mantine/core';

import { IconSearch } from '@tabler/icons';
import { useEffect, useState } from "react";
import { searchMovie } from "../api/api-movies";
import { CardMovieList } from "../components/organisms/CardMovieList"
import { Movie } from "../utils/types"

export function SearchMovie() {

  const theme = useMantineTheme();

  const [inputValue, setInputValue] = useState('')
  const [movieList, setMovieList] = useState<Movie[] | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (inputValue) {

      setIsLoading(true);

      const delayDebounceFn = setTimeout(() => {
        searchMovie(inputValue).then(movies => {
          setMovieList(movies)
          setIsLoading(false);
        });
      }, 1000)

      return () => clearTimeout(delayDebounceFn)

    } else {
      setMovieList(null);
      setIsLoading(false);
    }
  }, [inputValue])

  useEffect(() => {

  })

  return (
    <>
      <Container>

        <Container size={420} my={40}>
          <FocusTrap active={true}>
            <TextInput
              icon={<IconSearch size={18} stroke={1.5}/>}
              radius="xl"
              size="md"
              data-autofocus
              rightSection={
                isLoading && (
                  <Loader color="white" size={18}/>
                )
              }
              placeholder="Cerca un film da aggiungere"
              rightSectionWidth={42}
              onChange={(e) => setInputValue(e.currentTarget.value)}
            />
          </FocusTrap>
        </Container>

      </Container>

      {
        movieList &&
          <CardMovieList
            movieList={movieList}
            isLoading={isLoading}
            fluid
          />
      }

    </>
  );
}
