import { useEffect, useState } from "react";
import { Title, Button, Group, Container, LoadingOverlay, Space, Skeleton } from "@mantine/core";
import { getMovie, getMovieExt } from "../api/api-movies"
import { Movie } from "../utils/types"
import { CardMovieCarousel } from "../components/organisms/CardMovieCarousel"
import { useAppSelector } from "../utils/hooks"
import { Spacer } from "../components/atoms/Spacer"
import { NavLink } from "react-router-dom"


export function Dashboard() {

  const [isLoading, setIsLoading] = useState(true)
  const [moviePopularList, setMoviePopularList] = useState<Movie[] | null>(null)
  const [movieInTheatres, setMovieInTheatres] = useState<Movie[] | null>(null)
  const [movieWatchlist, setMovieWatchlist] = useState<Movie[] | null>(null)

  const movieListState = useAppSelector((state) => state.movieList)

  useEffect(() => {

		getMovieExt('popular').then(res => {
      setMoviePopularList(res.list)
    })

    getMovieExt('inTheatres').then(res => {
      setMovieInTheatres(res.list)
    })

    movieWatchlist && setIsLoading(false)

  }, [])

  useEffect(() => {
    if (movieListState.movieList) {

      const updateMovieList = async () => {

        const currentMovieList = await Promise.all(
          movieListState.movieList!
            .filter(movie => movie.watched === false)
            .slice(0, 6)
            .map(async movie => {
              return await getMovie(parseInt(movie.id))
            })
        )

        setMovieWatchlist(currentMovieList);
      }
      updateMovieList()

      setIsLoading(false);

    }
  }, [movieListState.movieList]);

  return (
		<>
      {
        (movieWatchlist && movieWatchlist?.length > 0) &&
          <>
            <Container>
              <Group position="apart" align="center" mb="xl">
                <Title order={4}>Ultimi aggiunti</Title>
                <Button variant="outline" component={NavLink} to="/watchlist">Vedi tutti</Button>
              </Group>
            </Container>

            <CardMovieCarousel
              id={1}
              movieList={movieWatchlist}
              isLoading={isLoading}
            />

            <Spacer smallerXs={80} largerXs={50} />
          </>
      }

      <>
        <Container>
          <Group position="apart" align="center" mb="xl">
            <Title order={4}>I più popolari</Title>
          </Group>
        </Container>

        <CardMovieCarousel
          id={2}
          movieList={moviePopularList}
          isLoading={isLoading}
          loop
        />

        <Spacer smallerXs={80} largerXs={50} />
      </>

      <>
        <Container>
          <Group position="apart" align="center" mb="xl">
            <Title order={4}>Ora nelle sale</Title>
          </Group>
        </Container>

        <CardMovieCarousel
          id={3}
          movieList={movieInTheatres}
          isLoading={isLoading}
          loop
        />

        <Spacer smallerXs={80} largerXs={50} />
      </>
		</>
  )

}
