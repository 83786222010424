import { Avatar, createStyles, Group, Loader, Text, TextProps } from "@mantine/core"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { IconStar } from "@tabler/icons"

const useStyles = createStyles((theme) => ({
  wrapper: {
    verticalAlign: "middle",
  },

  wrapperWithSrc: {
    verticalAlign: "middle",
    display: "inline-block",

    "&:hover": {
      color: theme.primaryColor
    }
  },

  star: {
    fill: theme.colorScheme === 'dark' ? theme.colors.dark[0] : 'black'
  }
}));

interface Props {
  name: string,
  src?: string,
  secondField?: string,
  size?: number;
  sizeName?: TextProps["size"],
  favourite?: boolean
}

export const AvatarUser = ({ src, name, size, sizeName, secondField, favourite }: Props) => {

  const [imgIsLoaded, setImgIsLoaded] = useState(false);

  const { classes } = useStyles();

  const inset = () => (
    <div>
      <Group>
        <Text size={sizeName || "sm"}>
          {name}
        </Text>
        {
          favourite && <IconStar size={12} className={classes.star}/>
        }
      </Group>
      {
        secondField &&
        <Text size={"xs"} color="dimmed">
          {secondField}
        </Text>
      }
    </div>
  )

  // Gli avatar senza src non hanno il link, ovvero non sono utenti
  return (
    <>
      {
        src ?
          <Text
            component={Link}
            to={`/friends/${name.toLowerCase()}`}
            className={classes.wrapperWithSrc}
          >
            <Group spacing="sm">
              <Avatar
                onLoad={() => {
                  setImgIsLoaded(true)
                }}
                src={src}
                size={size || 24}
                radius="xl"
              >
                {
                  !imgIsLoaded && <Loader color="gray" size={size ? (size / 1.6) : 18} radius="xl"/>
                }
              </Avatar>
              {inset()}
            </Group>
          </Text>
          :
          <Text
            className={classes.wrapper}
          >
            <Group spacing="sm">
              <Avatar
                size={size || 24}
                radius="xl"
              />
              {inset()}
            </Group>
          </Text>
      }

    </>
  )
}
