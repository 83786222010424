import { Friend, Movie } from "../utils/types";
import ls from 'localstorage-slim';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
  deleteDoc,
  updateDoc, addDoc
} from "firebase/firestore"
import { db } from "./api-firebase"
import emailjs from '@emailjs/browser'
import friendList from "../store/reducers/friendList"
import { sendNotification } from "./api-notifications"


// Get movie watchlist da Firebase
export const getUserFromDocId = async(docId: string) => {
  const userDocRef = doc(db, "users", docId);
  const user = await getDoc(userDocRef);
  let userUpdated = user.data();
  userUpdated && (userUpdated.docId = docId);
  return userUpdated;
}

export const getUserFromRef = async (ref: any, refIsDocId?: boolean, debug?: boolean) => {

  // Cerca l'utente nel localStorage, altrimenti lo fetcha

  const lsUsers = ls.get('users')
  let user;
  let refId = refIsDocId ? ref : ref.id;

  if (lsUsers) {

    // c'è almeno un utente nel local storage
    if (lsUsers instanceof Array) {

      // Controllo se c'è l'utente nel localStorage
      const checkUsersLs = lsUsers.some(user => user.refId === refId)

      if (checkUsersLs) {

        // L'utente è nel localstorage, lo recupero
        const searchUser = lsUsers.filter(user => user.refId === refId)
        user = searchUser[0];

        debug && console.log(`utente ${user.data.name} recuperato dal localstorate`)

      } else {

        // L'utente non è nel localstorage, lo fetcho
        let fetchUser;
        if (!refIsDocId) {
          fetchUser = await getDoc(ref);
          user = {
            refId: refId,
            data: fetchUser.data()
          };
        } else {
          fetchUser = await getUserFromDocId(ref);
          user = {
            refId: refId,
            data: fetchUser
          }
        }

        lsUsers.push(user);

        debug && console.log(`utente è stato fetchato`)
      }

      // Aggiorno il localstorage
      ls.set('users', lsUsers, { ttl: 172800 }); // 2 days ttl

    }

  } else {

    // Non c'è nessun utente

    // Non ci sono utenti nel localstorage
    let users = [];
    let fetchUser;
    if (!refIsDocId) {
      fetchUser = await getDoc(ref);
      user = {
        refId: refId,
        data: fetchUser.data()
      };
    } else {
      fetchUser = await getUserFromDocId(ref);
      user = {
        refId: refId,
        data: fetchUser
      }
    }

    users.push(user);

    debug && console.log(`utente è stato fetchato ed è stato creato per la prima volta il localstorage`)

    ls.set('users', users, { ttl: 172800 }); // 2 days ttl

  }

  return user;
}

export const searchFriends = async (string: string, excludeSearcher?: string, onlyFriendsOfDocId?: string) => {

  // String è la stringa che l'utente sta cercando
  // excludeSearcher è il nome utente di chi cerca in lowercase per escluderlo dalla ricerca
  // onlyFriendsOfDocId è il docId dell'utente che vuole cercare solo tra i suoi amici

  // Substring query workaround
  let q;
  if (excludeSearcher) {
    q = query(
      collection(db, "users"),
      orderBy('nameLowercase', 'desc'),
      where('nameLowercase', '>=', string.toLowerCase()),
      where('nameLowercase', '<', string.toLowerCase() +  '\uf8ff'),
      where('nameLowercase', '!=', excludeSearcher),
    );
  } else {
    q = query(
      collection(db, "users"),
      orderBy('nameLowercase', 'desc'),
      where('nameLowercase', '>=', string.toLowerCase()),
      where('nameLowercase', '<', string.toLowerCase() +  '\uf8ff')
    );
  }
  const res = await getDocs(q);
  const docs = res.docs;

  let friends: Friend[] = [];
  let friendList: string[] = [];

  // Prendo la lista amici se scelgo di cercare solo tra gli amici
  if (onlyFriendsOfDocId) {

    const q = query(
      collection(db, "users", onlyFriendsOfDocId, "friendList"),
      where('inPending', '==', false),
      where('isAccepted', '==', true)
    );
    const res = await getDocs(q);
    const docs = res.docs;

    docs.map((friendDoc: any) => {
      return friendList = [...friendList,
        friendDoc.id
      ]
    })
  }

  docs.map((friendDoc: any) => {

    const friend = friendDoc.data();
    const friendDocId = friendDoc.id;

    // Se cerco solo tra gli amici, controllo se il doc id è nella lista amici dell'utente.
    // Se non è nella lista, non faccio il return dell'utente ciclato
    if (onlyFriendsOfDocId && !friendList.includes(friendDocId)) {
      return true
    }

    return friends = [...friends, {
      id: friendDoc.id, // DocID dello user
      name: friend.name,
      nameLowercase: friend.nameLowercase,
      avatar: friend.avatar,
      email: friend.email
    }]
  })

  return friends
}

export const searchFriend = async (username: string) => {

  // Prendo dettagli utente
  const q = query(
    collection(db, "users"),
    orderBy('nameLowercase', 'desc'),
    where('nameLowercase', '>=', username.toLowerCase()),
    where('nameLowercase', '<', username.toLowerCase() +  '\uf8ff')
  );

  const res = await getDocs(q);
  const docs = res.docs;

  const userData = docs[0].data();
  const userDocId = docs[0].id;
  const user: any = {
    docId: userDocId,
    ...userData
  }

  // Prendo lista film utente
  const userMovieListRef = collection(db, "users", userDocId, 'movieList');
  const getUserMovieList = await getDocs(userMovieListRef);
  const movieListDocs = getUserMovieList.docs;

  let userMovieList: any[] = [];

  movieListDocs.map(movieDoc => {
    const movieDetail = movieDoc.data()
    return userMovieList = [...userMovieList, {
      ...movieDetail,
      id: movieDoc.id // docId (che coincide con id) del film
    }]
  })

  // Prendo lista amici dell'utente (solo già confermati e non in pending)
  const userFriendListRef = query(
    collection(db, "users", userDocId, 'friendList'),
    where('inPending', '==', false),
    where('isAccepted', '==', true)
  );
  const getUserFriendList = await getDocs(userFriendListRef);
  const friendListDocs = getUserFriendList.docs;

  let userFriendList: string[] = [];

  friendListDocs.map(friendDoc => {
    return userFriendList = [...userFriendList, friendDoc.id]
  })

  // Return dell'oggetto completo
  return {
    data: user,
    movieList: userMovieList,
    friendList: userFriendList
  }

}

export const friendshipRequest = async (userFrom: string, userTo: string, fromName: string) => {

  // From e to sono i 2 docId
  // From è sempre l'utente loggato, to è sempre l'utente da confrontare

  const refUserFrom = doc(db, 'users', userFrom, 'friendList', userTo);
  const refUserTo = doc(db, 'users', userTo, 'friendList', userFrom);
  const userRequestedDetail = await getUserFromDocId(userTo);

  // Inserisco il mittente nella lista amici del destinatario
  await setDoc(refUserTo, {
    'added_on': serverTimestamp(),
    'isAccepted': false,
    'inPending': true
  })

  // Inserisco il destinatario nella lista amici del mittente
  await setDoc(refUserFrom, {
    'added_on': serverTimestamp(),
    'isAccepted': true,
    'inPending': true
  })

  // Invio email al destinatario
  userRequestedDetail &&
    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICEID || '',
      process.env.REACT_APP_TEMPLATE_REQUEST_FRIENDSHIP || '',
      {from_name: fromName, to_email: userRequestedDetail.email, to_name: userRequestedDetail.name},
      process.env.REACT_APP_EMAILJS_PUBLICKEY
    )

  // Invio notifica al destinatario
  await sendNotification(
    userTo,
    "friendshipRequest",
    `${fromName} ti ha inviato una richiesta di amicizia`,
    userFrom
  )

  return true

}

export const friendshipConfirm = async (userFrom: string, userTo: string, toName: string) => {

  // From e to sono i 2 docId

  const refUserTo = doc(db, 'users', userTo, 'friendList', userFrom);
  const refUserFrom = doc(db, 'users', userFrom, 'friendList', userTo);
  const userSenderDetail = await getUserFromDocId(userTo); // Dettagli destinatario

  // Aggiorno il mio valore riguardante l'amico che ha richiesto l'amicizia
  await updateDoc(refUserFrom, {
    'isAccepted': true,
    'inPending': false,
  })

  // Aggiorno il valore dell'amico che ha richiesto l'amicizia
  await updateDoc(refUserTo, {
    'isAccepted': true,
    'inPending': false
  })

  // Invio email al mittente della richiesta
  userSenderDetail &&
    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICEID || '',
      process.env.REACT_APP_TEMPLATE_CONFIRM_FRIENDSHIP || '',
      {from_name: userSenderDetail.name, to_email: userSenderDetail.email, to_name: toName},
      process.env.REACT_APP_EMAILJS_PUBLICKEY
    )

  // Invio notifica al mittente della richiesta
  await sendNotification(
    userTo,
    "friendshipAccepted",
    `${toName} ha accettato la tua richiesta di amicizia`,
    userFrom
  )

  return true

}

export const friendshipRemove = async (userFrom: string, userTo: string) => {

  // From e to sono i 2 docId

  const refUserTo = doc(db, 'users', userTo, 'friendList', userFrom);
  const refUserFrom = doc(db, 'users', userFrom, 'friendList', userTo);

  // Rimuovo
  await deleteDoc(refUserFrom)
  await deleteDoc(refUserTo)

  return true

}

export const friendToFavourite = async (userFrom: string, userTo: string, favourite: 'add' | 'remove') => {

  // Aggiunge un amico ai preferiti

  const refUser = doc(db, 'users', userFrom, 'friendList', userTo);

  await updateDoc(refUser, {
    'favourite': favourite === 'add'
  })

  return true

}
