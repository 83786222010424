import { Group, Tooltip, useMantineTheme } from "@mantine/core"
import { apiImagePath } from "../../utils/constants"
import { ListSimpleItem } from "./ListSimpleItem"

interface Props {
  providers: any[];
  label: string;
}

export const ListProviders = ({ providers, label }: Props) => {

  const theme = useMantineTheme();

  return (
    <>
      <ListSimpleItem
        label={label}
        value={
          <Group spacing="xs">
            {
              providers.map((provider: any) => (
                <Tooltip
                  key={provider.provider_id}
                  label={provider.provider_name}
                  color={theme.colors.dark[5]}
                  withArrow
                >
                  <img
                    width={30}
                    alt={provider.provider_name}
                    src={`${apiImagePath}/original/${provider.logo_path}`}
                  />
                </Tooltip>
              ))
            }
          </Group>
        }
      />
    </>
  )
}
