import { Container, Skeleton, AspectRatio } from '@mantine/core';
import { Movie } from "../../utils/types"
import { CardMovie } from "./CardMovie"
import { Carousel } from "@mantine/carousel"
import { useMediaQuery } from "@mantine/hooks"
import React from "react"

interface Props {
  id: number;
  movieList: Movie[] | null;
  isLoading: boolean;
  loop?: boolean;
}

export function CardMovieCarousel({ movieList, isLoading, loop, id }: Props) {

  const largeScreen = useMediaQuery('(min-width: 768px)');

  return (
    <Container>
      <Carousel
        key={id}
        withIndicators={false}
        withControls={largeScreen}
        slideSize="33.05%"
        slideGap="md"
        loop={loop}
        align="start"
        slidesToScroll={1}
        breakpoints={[
          {
            maxWidth: 'md',
            slideSize: '50%'
          },
          {
            maxWidth: 'sm',
            slideSize: '80%'
          },
        ]}
      >
        {
          !isLoading && movieList ?
            movieList.map((movie: Movie) => (
              <Carousel.Slide key={movie.id}>
                <CardMovie
                  movie={movie}
                />
              </Carousel.Slide>
            )) :
            [...Array(3)].map((item: number, i: number) => (
              <Carousel.Slide key={i}>
                <AspectRatio ratio={2/3}>
                  <Skeleton />
                </AspectRatio>
              </Carousel.Slide>
            ))
        }
      </Carousel>
    </Container>
  );
}
