import {
  Title,
  Container,
  Group,
  Button, SimpleGrid, Text, Table,
} from '@mantine/core';

import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom"
import { useAppSelector } from "../utils/hooks"
import { getUserFromDocId, getUserFromRef } from "../api/api-users"
import { AvatarUser } from "../components/molecules/AvatarUser"
import { BtnHandleFriend } from "../components/molecules/BtnHandleFriend"
import { SkeletonFriends } from "../components/molecules/SkeletonFriends"
import { sortUserBy } from "../utils/utils"

export function Friends() {

  const [friendsList, setFriendsList] = useState<any[] | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const friendListStore = useAppSelector(state => state.friendList.friendList)
  const userState = useAppSelector((state) => state.user)

  useEffect(() => {
    if (friendListStore) {

      setIsLoading(true);

      // Prendo tutti gli amici accettati o in pending
      const getFriendListDetailed = async () => {
        const friendListDetailed = await Promise.all(
          friendListStore
            .filter(user => ((!user.inPending && user.isAccepted) || user.inPending))
            .map(async user => {
              const userFromRef = await getUserFromRef(user.docId, true)
              return {
                ...userFromRef,
                personalInfo: user // Informazioni dell'utente loggato sull'amico
              }
            })
        )
        const sortedFriendList = sortUserBy(friendListDetailed, 'name', 'asc', true)
        setFriendsList(sortedFriendList);
        setIsLoading(false);
      }
      getFriendListDetailed();

    }
  }, [friendListStore])

  return (
    <>
      <Container size="sm">

        {
          (friendsList && friendsList.length > 0 && !isLoading) ?
            <>
              <Container>
                <Group position="apart" align="center" mb="xl">
                  <Title order={4}>I tuoi amici</Title>
                  <Button variant="outline" component={NavLink} to="/friends/search">Cerca un amico</Button>
                </Group>
              </Container>
              <Table verticalSpacing="xl">
                <tbody>
                {
                  friendsList.map((friend, i) => (
                    <tr key={friend.refId}>
                      <td>
                        <AvatarUser
                          src={friend.data.avatar || ''}
                          name={friend.data.name || ''}
                          // secondField={friend.data.email}
                          size={40}
                          favourite={friend.personalInfo.favourite}
                        />
                      </td>
                      <td align="right">
                        <BtnHandleFriend
                          position="right"
                          size="xs"
                          userFrom={userState.data.docId}
                          userTo={friend.refId}
                        />
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </>
          : (friendsList && friendsList.length <= 0) ?
            <Container style={{textAlign: 'center'}}>
              <SimpleGrid>
                <div>
                  {
                    <>
                      <Title order={1}>Lista amici vuota</Title>
                      <Text color="dimmed" size="lg">
                        Puoi cercare e aggiungere amici, consultare le loro watchlist e consigliare nuovi film da vedere
                      </Text>
                      <Button
                        component={NavLink}
                        to="/friends/search"
                        variant="outline"
                        size="md"
                        mt="xl"
                      >
                        Aggiungi un amico
                      </Button>
                    </>
                  }
                </div>
              </SimpleGrid>
            </Container>
          :
            <>
              {
                [...Array(8)].map((item: number, i: number) => (
                  <SkeletonFriends key={i} />
                ))
              }
            </>
        }

      </Container>

    </>
  );
}
