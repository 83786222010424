import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  friendList: <any[] | null> null
};

const userSlice = createSlice({
  name: 'friendList',
  initialState,
  reducers: {
    updateFriendList(state, action) {
      state.friendList = action.payload;
    },
    resetFriendList(state) {
      state.friendList = [];
    }
  },
});

export const { updateFriendList, resetFriendList } = userSlice.actions;

export default userSlice.reducer
