import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  movieList: <any[] | null> null
};

const userSlice = createSlice({
  name: 'movieList',
  initialState,
  reducers: {
    updateMovieList(state, action) {
      state.movieList = action.payload;
    },
    resetMovieList(state) {
      state.movieList = [];
    }
  },
});

export const { updateMovieList, resetMovieList } = userSlice.actions;

export default userSlice.reducer
